<template>
    <v-container class="w-container v-overflow budgetPerfPrev"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">


        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="d_budget_previsto"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Budget
                                        Previsto</label>

                                    <vue-autonumeric v-model="budgetPrevisto" :disabled="true" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>
        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>




            <div class="contpopupbudgetprev">

                <template>
                    <modal name="popupBudgetPrev" :clickToClose="false" :width="300" :height="170">

                        <v-row>

                            <v-col cols="12" md="12" style="margin-top:10px!important">

                                <label style="font-size:15px;margin-left:8px">Budget Previsto</label>

                                <div id="d_budget_prev" style="cursor:pointer"
                                    class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                    <div class="v-input__control">
                                        <div class="v-input__slot">
                                            <div class="v-text-field__slot"><label for="input-29"
                                                    class="v-label v-label--active theme--light"
                                                    style="left: 0px; right: auto; position: absolute;"></label>

                                                <vue-autonumeric v-model="valoreBudgetPrevisto" :options="{
                                                    digitGroupSeparator: '.',
                                                    decimalCharacter: ',',
                                                    decimalCharacterAlternative: '.',
                                                    currencySymbol: '\u00a0€',
                                                    currencySymbolPlacement: 's',
                                                    roundingMethod: 'U',
                                                    minimumValue: '0'
                                                }"></vue-autonumeric>

                                            </div>
                                        </div>

                                        <div class="v-text-field__details">
                                            <div class="v-messages theme--light">
                                                <div class="v-messages__wrapper"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </v-col>

                        </v-row>


                        <v-row>

                            <v-col cols="12" md="12">

                                <div style="display:flex;justify-content:center">

                                    <v-img alt="" class="btn-cancel-budget-prev" contain
                                        src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                        style="cursor:pointer;margin-right:10px" transition="scale-transition"
                                        @click="btnCancelBudgetPrev" />

                                    <v-img alt="" class="btn-confirm-budget-prev" contain
                                        src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                        style="cursor:pointer" transition="scale-transition"
                                        @click="btnConfirmBudgetPrev" />


                                </div>

                            </v-col>

                        </v-row>

                    </modal>
                </template>

            </div>







        </template>

    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        // console.log("PARAMS: ", this.$route.params.id);

        // console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").nuclei);

        // this.cur_id = this.$route.params.id;

        var pointerVue = this;

        pointerVue.$root.$children[0].normalDiv1 = true;
        pointerVue.$root.$children[0].normalDiv2 = true;

        pointerVue.$root.$children[0].invoiceDiv1 = false;
        pointerVue.$root.$children[0].invoiceDiv2 = false;
        pointerVue.$root.$children[0].invoiceDiv3 = false;
        pointerVue.$root.$children[0].invoiceDiv4 = false;


        pointerVue.$root.$children[0].curTitle = "Performance Budget";



        this.setupButtons();

        setTimeout(() => {

            this.syncAziende();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '#d_budget_previsto', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupBudgetPrev');

            });




        });

        pointerVue.hideShowButtons(false);

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        budgetPrevisto: null,
        valoreBudgetPrevisto: null,

        titolo: "",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {

        btnCancelBudgetPrev: function () {

            this.$modal.hide('popupBudgetPrev');


        },


        btnConfirmBudgetPrev: function () {

            this.budgetPrevisto = this.valoreBudgetPrevisto;

            this.$modal.hide('popupBudgetPrev');

            this.hideShowButtons(true);

        },


        getFirstDayMonth: function () {

            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var elements = firstDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },


        getLastDayMonth: function () {

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.selAzienda = azienda.companyid;

            this.hideShowButtons(true);

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/conferma-prjweb.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        title: "Conferma",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_ok_32.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    class: "inverted",
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            // console.log("AZIENDA: ", that.selAzienda);


            // // var firstDay = this.getFirstDayMonth();
            // var lastDay = this.getLastDayMonth();

            // window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
            // window.$cookies.set("sel_filter_inv_date_scad_to", lastDay, "9y");

            // window.$cookies.set("sel_filter_inv_date_emiss_from", "", "9y");
            // window.$cookies.set("sel_filter_inv_date_emiss_to", "", "9y");

            window.$cookies.set("sel_budget_prev", that.budgetPrevisto, "9y");

            router.push({
                path: "/budgetPerformanceRisultati"
            });

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.budgetPerfPrev .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}


.budgetPerfPrev .v-input {
    font-size: 1.2em !important;
}

.budgetPerfPrev .v-select {
    font-size: 1.2em !important;
}

.budgetPerfPrev .v-label {
    font-size: 1em !important;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
