<template>
    <v-container class="w-container-livelli-gm v-overflow editLivelliGM"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont'
                style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:9999!important;z-index:3">


                <div class="mainTitle" style="
    background-color: white!important;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;                
    min-width:100vw;
    border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form style="max-width:400px;" v-model="valid" ref="form" lazy-validation>

            <v-row>
                <v-col cols="4" md="4">
                    <label style="font-weight:bold">Scadenza</label>
                </v-col>
                <v-col cols="4" md="4">
                    <label style="font-weight:bold">(>) Da (GG)</label>
                </v-col>
                <v-col cols="4" md="4">
                    <label style="font-weight:bold">{{ label3Col }}</label>
                </v-col>

            </v-row>

            <v-row>

                <v-col cols="4" md="4">

                    <v-img style="margin-left:16px!important" alt="Vuetify Logo" class="shrink mr-2" contain
                        src="@/assets/ball_green.png" transition="scale-transition" width="12" />

                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_da_level_green" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpLivGreen" v-model="daLivelloGreen" :disabled="true"
                                        :options="'integer'"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>
                <v-col cols="4" md="4">
                </v-col>

            </v-row>

            <v-row>

                <v-col cols="4" md="4">

                    <v-img style="margin-left:16px!important" alt="Vuetify Logo" class="shrink mr-2" contain
                        src="@/assets/ball_yellow.png" transition="scale-transition" width="12" />

                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_da_level_yellow" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpDaLivYellow" v-model="daLivelloYellow" :disabled="true"
                                        :options="'integer'"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_a_level_yellow" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpALivYellow" v-model="aLivelloYellow" :disabled="true"
                                        :options="'integer'"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>


                </v-col>


            </v-row>


            <v-row>

                <v-col cols="4" md="4">

                    <v-img style="margin-left:16px!important" alt="Vuetify Logo" class="shrink mr-2" contain
                        src="@/assets/ball_red.png" transition="scale-transition" width="12" />

                </v-col>
                <v-col cols="4" md="4">
                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_a_level_red" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpALivRed" v-model="aLivelloRed" :disabled="true"
                                        :options="'integer'"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>


                </v-col>


            </v-row>





            <v-row>
                <v-col cols="4" md="4">
                    <label style="font-weight:bold">Rimanente</label>
                </v-col>
                <v-col cols="4" md="4">
                    <label style="font-weight:bold">(>) Da (€)</label>
                </v-col>
                <v-col cols="4" md="4">
                    <label style="font-weight:bold">{{ label3ColRemaining }}</label>
                </v-col>

            </v-row>

            <v-row>

                <v-col cols="4" md="4">

                    <v-img style="margin-left:16px!important" alt="Vuetify Logo" class="shrink mr-2" contain
                        src="@/assets/ball_green.png" transition="scale-transition" width="12" />

                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_da_level_green_remaining" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpLivGreenRemaining" v-model="daLivelloGreenRemaining"
                                        :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>
                <v-col cols="4" md="4">
                </v-col>

            </v-row>

            <v-row>

                <v-col cols="4" md="4">

                    <v-img style="margin-left:16px!important" alt="Vuetify Logo" class="shrink mr-2" contain
                        src="@/assets/ball_yellow.png" transition="scale-transition" width="12" />

                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_da_level_yellow_remaining" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpDaLivYellowRemaining" v-model="daLivelloYellowRemaining"
                                        :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_a_level_yellow_remaining" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpALivYellowRemaining" v-model="aLivelloYellowRemaining"
                                        :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>


                </v-col>


            </v-row>


            <v-row>

                <v-col cols="4" md="4">

                    <v-img style="margin-left:16px!important" alt="Vuetify Logo" class="shrink mr-2" contain
                        src="@/assets/ball_red.png" transition="scale-transition" width="12" />

                </v-col>
                <v-col cols="4" md="4">
                </v-col>
                <v-col cols="4" md="4">

                    <div id="d_a_level_red_remaining" style="transform: translate(-10px, -24px);"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                    <vue-autonumeric id="inpALivRedRemaining" v-model="aLivelloRedRemaining"
                                        :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>


                </v-col>


            </v-row>












        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <div class="contpopupfidoant">

            <template>
                <modal name="popupFidoAnt" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Fido Anticipi</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="fidoAnt" :options="'integer'" ref="fldFidoAnt"
                                                id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-fido-ant" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-fido-ant" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupfidocassa">

            <template>
                <modal name="popupFidoCassa" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Fido Cassa</label>

                            <div id="d_fido_cassa" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="fidoCassa" :options="'integer'" ref="fldFidoCassa"
                                                id="fldFidoCassa"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-fido-cassa" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-fido-cassa" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupcostoprat">

            <template>
                <modal name="popupCostoPrat" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Costo</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valCostoPratica" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-costo-prat" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelCostoPrat" />

                                <v-img alt="" class="btn-confirm-costo-prat" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" @click="btnConfirmCostoPrat" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupdalevelgreen">

            <template>
                <modal name="popupDaLevelGreen" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">(>) Da</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valDaLevelGreen" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-da-lev-green" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelDaLevelGreen" />

                                <v-img alt="" class="btn-confirm-da-lev-green" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmDaLevelGreen" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupdalevelyellow">

            <template>
                <modal name="popupDaLevelYellow" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">(>) Da</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valDaLevelYellow" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-da-lev-yellow" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelDaLevelYellow" />

                                <v-img alt="" class="btn-confirm-da-lev-yellow" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmDaLevelYellow" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupalevelyellow">

            <template>
                <modal name="popupALevelYellow" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">{{ label3Col }}</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valALevelYellow" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-a-lev-yellow" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelALevelYellow" />

                                <v-img alt="" class="btn-confirm-a-lev-yellow" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmALevelYellow" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>






        <div class="contpopupalevelred">

            <template>
                <modal name="popupALevelRed" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">{{ label3Col }}</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valALevelRed" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-a-lev-red" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelALevelRed" />

                                <v-img alt="" class="btn-confirm-a-lev-red" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" @click="btnConfirmALevelRed" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <!-- scadenze -->

        <div class="contpopupdalevelgreen">

            <template>
                <modal name="popupDaLevelGreen" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">(>) Da</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valDaLevelGreen" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-da-lev-green" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelDaLevelGreen" />

                                <v-img alt="" class="btn-confirm-da-lev-green" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmDaLevelGreen" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupdalevelyellow">

            <template>
                <modal name="popupDaLevelYellow" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">(>) Da</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valDaLevelYellow" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-da-lev-yellow" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelDaLevelYellow" />

                                <v-img alt="" class="btn-confirm-da-lev-yellow" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmDaLevelYellow" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupalevelyellow">

            <template>
                <modal name="popupALevelYellow" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">{{ label3Col }}</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valALevelYellow" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-a-lev-yellow" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelALevelYellow" />

                                <v-img alt="" class="btn-confirm-a-lev-yellow" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmALevelYellow" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupalevelred">

            <template>
                <modal name="popupALevelRed" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">{{ label3Col }}</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valALevelRed" :options="'integer'"
                                                ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-a-lev-red" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelALevelRed" />

                                <v-img alt="" class="btn-confirm-a-lev-red" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" @click="btnConfirmALevelRed" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>









        <!-- rimanente -->

        <div class="contpopupdalevelgreenremaining">

            <template>
                <modal name="popupDaLevelGreenRemaining" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">(>) Da</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valDaLevelGreenRemaining" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-da-lev-green" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelDaLevelGreenRemaining" />

                                <v-img alt="" class="btn-confirm-da-lev-green" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmDaLevelGreenRemaining" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupdalevelyellowremaining">

            <template>
                <modal name="popupDaLevelYellowRemaining" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">(>) Da</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valDaLevelYellowRemaining" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-da-lev-yellow" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelDaLevelYellowRemaining" />

                                <v-img alt="" class="btn-confirm-da-lev-yellow" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmDaLevelYellowRemaining" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupalevelyellowremaining">

            <template>
                <modal name="popupALevelYellowRemaining" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">{{ label3ColRemaining }}</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valALevelYellowRemaining" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-a-lev-yellow" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelALevelYellowRemaining" />

                                <v-img alt="" class="btn-confirm-a-lev-yellow" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmALevelYellowRemaining" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupalevelredremaining">

            <template>
                <modal name="popupALevelRedRemaining" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">{{ label3ColRemaining }}</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valALevelRedRemaining" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-a-lev-red" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelALevelRedRemaining" />

                                <v-img alt="" class="btn-confirm-a-lev-red" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmALevelRedRemaining" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>





    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apibanche from "../utils/banche/apibanche";
import apiordini from "../utils/ordini/apiordini";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';



export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

       // this.$root.$children[0].curTitle = "";

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;

        //window.$cookies.get("aziende_user")


        pointerVue.aziende = window.ddlAziende;

        this.setupButtons();



        setTimeout(() => {


            this.syncGMLevel();


        }, 100);





        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.saveData();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/ordiniClientiAgila"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('click', '#d_da_level_green', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupDaLevelGreen');

            });

            $('body').on('click', '#d_da_level_yellow', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupDaLevelYellow');

            });

            $('body').on('click', '#d_a_level_yellow', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupALevelYellow');

            });

            $('body').on('click', '#d_a_level_red', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupALevelRed');

            });






            $('body').on('click', '#d_da_level_green_remaining', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupDaLevelGreenRemaining');

            });

            $('body').on('click', '#d_da_level_yellow_remaining', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupDaLevelYellowRemaining');

            });

            $('body').on('click', '#d_a_level_yellow_remaining', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupALevelYellowRemaining');

            });

            $('body').on('click', '#d_a_level_red_remaining', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupALevelRedRemaining');

            });






















            $('body').on('click', '#d_costoprat', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupCostoPrat');

            });

            $('body').on('click', '.btn-confirm-fido-ant', function (event) {
                console.log(event);

                pointerVue.banca.fido_anticipi = pointerVue.fidoAnt;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_ant");

                pointerVue.$modal.hide('popupFidoAnt');

            });


            $('body').on('click', '.btn-cancel-fido-ant', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoAnt');

            });

            $('body').on('click', '#d_fido_ant', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoAnt');

            });


            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });



            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });





        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },



    },

    data: () => ({

        aLivelloRed: "",
        valALevelRed: 0,
        daLivelloYellow: "",
        aLivelloYellow: "",
        valDaLevelYellow: 0,
        valALevelYellow: 0,
        valDaLevelGreen: 0,
        daLivelloGreen: "",


        aLivelloRedRemaining: "",
        valALevelRedRemaining: 0,
        daLivelloYellowRemaining: "",
        aLivelloYellowRemaining: "",
        valDaLevelYellowRemaining: 0,
        valALevelYellowRemaining: 0,
        valDaLevelGreenRemaining: null,
        daLivelloGreenRemaining: null,





        label3Col: "(<=) A (GG)",
        label3ColRemaining: "(<=) A (€)",

        nomePratica: "",
        nomeAzienda: "",
        ricavoPratica: 0,
        valCostoPratica: 0,
        costoPratica: 0,
        gmPratica: 0,

        fidoAnt: null,
        fidoCassa: null,

        sheetGruppi: false,

        searchGruppi: "",

        ordine: {},

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "Check Scadenze",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        enableSaveButton: function () {

            var enable = 1;

            if (this.daLivelloGreen == "") {
                enable = 0;
            }

            if (enable == 1) {

                if (this.daLivelloYellow == "") {
                    enable = 0;
                }

            }

            if (enable == 1) {

                if (this.aLivelloYellow == "") {
                    enable = 0;
                }

            }

            if (enable == 1) {

                if (this.aLivelloRed == "") {
                    enable = 0;
                }

            }


            return enable;
        },


        btnCancelALevelRed: function () {

            this.$modal.hide('popupALevelRed');

        },

        btnConfirmALevelRed: function () {


            var errore = "";



            if (errore == "") {

                this.aLivelloRed = this.valALevelRed;


                //$("#inpALivRed").val(this.valALevelRed.toFixed(2));
                $("#inpALivRed").val(this.valALevelRed);


                this.$root.$children[0].setFieldActive("#d_a_level_red");

                this.$modal.hide('popupALevelRed');

                this.setupButtons();

            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },






        btnCancelALevelYellow: function () {

            this.$modal.hide('popupALevelYellow');

        },

        btnConfirmALevelYellow: function () {


            var errore = "";

            if (errore == "") {

                this.aLivelloYellow = this.valALevelYellow;


                $("#inpALivYellow").val(this.valALevelYellow);


                this.$root.$children[0].setFieldActive("#d_a_level_yellow");

                this.$modal.hide('popupALevelYellow');

                this.setupButtons();


            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },


        btnCancelDaLevelYellow: function () {

            this.$modal.hide('popupDaLevelYellow');

        },

        btnConfirmDaLevelYellow: function () {


            var errore = "";

            if (errore == "") {

                this.daLivelloYellow = this.valDaLevelYellow;


                $("#inpDaLivYellow").val(this.valDaLevelYellow);


                this.$root.$children[0].setFieldActive("#d_da_level_yellow");

                this.$modal.hide('popupDaLevelYellow');

                this.setupButtons();


            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },


        btnCancelDaLevelGreen: function () {

            this.$modal.hide('popupDaLevelGreen');


        },

        btnConfirmDaLevelGreen: function () {

            console.log("VAL FROM POPUP: ", this.valDaLevelGreen);

            var errore = "";


            if (errore == "") {

                this.daLivelloGreen = this.valDaLevelGreen;


                $("#inpLivGreen").val(this.valDaLevelGreen);


                this.$root.$children[0].setFieldActive("#d_da_level_green");

                this.$modal.hide('popupDaLevelGreen');

                this.setupButtons();


            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },












        // remaining

        btnCancelALevelRedRemaining: function () {

            this.$modal.hide('popupALevelRedRemaining');

        },

        btnConfirmALevelRedRemaining: function () {


            var errore = "";



            if (errore == "") {

                this.aLivelloRedRemaining = this.valALevelRedRemaining;


                $("#inpALivRedRemaining").val(this.valALevelRedRemaining);


                this.$root.$children[0].setFieldActive("#d_a_level_red_remaining");

                this.$modal.hide('popupALevelRedRemaining');

                this.setupButtons();

            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },






        btnCancelALevelYellowRemaining: function () {

            this.$modal.hide('popupALevelYellowRemaining');

        },

        btnConfirmALevelYellowRemaining: function () {


            var errore = "";

            if (errore == "") {

                this.aLivelloYellowRemaining = this.valALevelYellowRemaining;


                $("#inpALivYellowRemaining").val(this.valALevelYellowRemaining);


                this.$root.$children[0].setFieldActive("#d_a_level_yellow_remaining");

                this.$modal.hide('popupALevelYellowRemaining');

                this.setupButtons();


            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },


        btnCancelDaLevelYellowRemaining: function () {

            this.$modal.hide('popupDaLevelYellowRemaining');

        },

        btnConfirmDaLevelYellowRemaining: function () {


            var errore = "";

            if (errore == "") {

                this.daLivelloYellowRemaining = this.valDaLevelYellowRemaining;


                $("#inpDaLivYellowRemaining").val(this.valDaLevelYellowRemaining);


                this.$root.$children[0].setFieldActive("#d_da_level_yellow_remaining");

                this.$modal.hide('popupDaLevelYellowRemaining');

                this.setupButtons();


            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },


        btnCancelDaLevelGreenRemaining: function () {

            this.$modal.hide('popupDaLevelGreenRemaining');


        },

        btnConfirmDaLevelGreenRemaining: function () {

            console.log("VAL FROM POPUP: ", this.valDaLevelGreenRemaining);

            var errore = "";


            if (errore == "") {

                this.daLivelloGreenRemaining = this.valDaLevelGreenRemaining;


                $("#inpLivGreenRemaining").val(this.valDaLevelGreenRemaining.toFixed(2));


                this.$root.$children[0].setFieldActive("#d_da_level_green_remaining");

                this.$modal.hide('popupDaLevelGreenRemaining');

                this.setupButtons();


            }

            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }

        },








































        btnCancelCostoPrat: function () {

            this.$modal.hide('popupCostoPrat');


        },

        btnConfirmCostoPrat: function () {

            this.costoPratica = this.valCostoPratica;

            // calcolo gm

            var v_rapp = (this.costoPratica / this.ricavoPratica) * 100;

            console.log("COSTO PRATICA; ", this.costoPratica);
            console.log("RICAVO PRATICA; ", this.ricavoPratica);
            console.log("RAPPORTO; ", v_rapp);


            var v_gm = 100 - v_rapp;

            console.log("GM; ", v_gm);



            this.gmPratica = v_gm;


            this.$root.$children[0].setFieldActive("#d_costoprat");

            this.$modal.hide('popupCostoPrat');


        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.banca.companyid = azienda.companyid;

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },

        setupButtons: async function () {

            //var v_enable = this.enableSaveButton();
            var v_enable = 1;

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-prjweb.png",
                        title: "Salva",
                        width: 30

                    });

                }


                // var pulsantis = [{
                //     text: "Salva",
                //     icon: "mdi-content-save",
                //     link: "/timesheet",
                //     id: "btn_save",
                //     disabled: false,
                //     image: "https://app.emtool.eu/public/_lib/img/save_update_data-prjweb.png",
                //     title: "Salva",
                //     width: 30

                // },
                // {
                //     text: "Parenti",
                //     icon: "mdi-arrow-left",
                //     link: "/parenti",
                //     id: "btn_back",
                //     disabled: false,
                //     title: "Indietro",
                //     image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                //     width: 35

                // },

                // ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },

        validate() {

            var that = this;

            console.log("BANCA: ", that.banca);

            var errore = "";

            if ($("#companyid").val() == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {

                if ($("#nome").val() == "") {
                    errore = "Specificare il nome";
                }

            }


            if (errore == "") {

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },


        syncGMLevel: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiordini.getCheckScadenze(
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCheckScadenze", res);

                that.daLivelloGreen = res.data.v_daLivelloGreen;
                that.daLivelloYellow = res.data.v_daLivelloYellow;
                that.aLivelloYellow = res.data.v_aLivelloYellow;
                that.aLivelloRed = res.data.v_aLivelloRed;

                that.daLivelloGreenRemaining = res.data.v_daLivelloGreenRemaining;
                that.daLivelloYellowRemaining = res.data.v_daLivelloYellowRemaining;
                that.aLivelloYellowRemaining = res.data.v_aLivelloYellowRemaining;
                that.aLivelloRedRemaining = res.data.v_aLivelloRedRemaining;

                $("#inpALivRed").val(parseInt(res.data.v_aLivelloRed));
                $("#inpALivYellow").val(parseInt(res.data.v_aLivelloYellow));
                $("#inpDaLivYellow").val(parseInt(res.data.v_daLivelloYellow));
                $("#inpLivGreen").val(parseInt(res.data.v_daLivelloGreen));


            

                $("#inpALivRedRemaining").val(parseFloat(res.data.v_aLivelloRedRemaining).toFixed(2));
                $("#inpALivYellowRemaining").val(parseFloat(res.data.v_aLivelloYellowRemaining).toFixed(2));
                $("#inpDaLivYellowRemaining").val(parseFloat(res.data.v_daLivelloYellowRemaining).toFixed(2));
                $("#inpLivGreenRemaining").val(parseFloat(res.data.v_daLivelloGreenRemaining).toFixed(2));






                this.setupButtons();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },





        syncInfoPratica: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiordini.getInfoPratica(
                v_token,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoPratica", res);


                that.nomePratica = res.data.nome_pratica;
                that.nomeAzienda = res.data.nome_azienda;
                that.ricavoPratica = res.data.ricavo_pratica;

                that.costoPratica = res.data.costo_pratica;
                that.gmPratica = res.data.gm_pratica;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("PRAT ID: ", that.$route.params.id);
            console.log("COSTO PRAT: ", that.costoPratica);
            console.log("GM PRAT: ", that.gmPratica);
            console.log("V_TOKEN: ", v_token);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response = await apiordini.updateCheckScadenze(
                v_token,
                that.daLivelloGreen,
                that.daLivelloYellow,
                that.aLivelloYellow,
                that.aLivelloRed,
                that.daLivelloGreenRemaining,
                that.daLivelloYellowRemaining,
                that.aLivelloYellowRemaining,
                that.aLivelloRedRemaining

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateCheckScadenze", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );












        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getBanca(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBanca", res);

                this.aziende = res.data.aziende;

                this.gruppi = res.data.invoice_mail_group;

                this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-livelli-gm {
    width: 400px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editLivelliGM .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editLivelliGM .v-input {
    font-size: 1.2em !important;
}

.editLivelliGM .v-select {
    font-size: 1.2em !important;
}

.editLivelliGM .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
