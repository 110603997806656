import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIOrdini {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;


  }

  async getInfoPratica(user, idpratica) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idpratica", idpratica);

    return axios.post(this.getUrlDomain("PrjWebAgilaGetInfoPrat"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateGMPratica(user, idpratica, costopratica, gmpratica) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idpratica", idpratica);
    formData.append("costopratica", costopratica);
    formData.append("gmpratica", gmpratica);

    return axios.post(this.getUrlDomain("PrjWebAgilaUpdGMPrat"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateGMLevels(user, daLivelloGreen, daLivelloYellow, aLivelloYellow, aLivelloRed) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_daLivelloGreen", daLivelloGreen);
    formData.append("v_daLivelloYellow", daLivelloYellow);
    formData.append("v_aLivelloYellow", aLivelloYellow);
    formData.append("v_aLivelloRed", aLivelloRed);

    return axios.post(this.getUrlDomain("PrjWebUpdGMLevels"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateCheckScadenze(user, daLivelloGreen, daLivelloYellow, aLivelloYellow, aLivelloRed, daLivelloGreenRemaining, daLivelloYellowRemaining, aLivelloYellowRemaining, aLivelloRedRemaining) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_daLivelloGreen", daLivelloGreen);
    formData.append("v_daLivelloYellow", daLivelloYellow);
    formData.append("v_aLivelloYellow", aLivelloYellow);
    formData.append("v_aLivelloRed", aLivelloRed);

    formData.append("v_daLivelloGreenRemaining", daLivelloGreenRemaining);
    formData.append("v_daLivelloYellowRemaining", daLivelloYellowRemaining);
    formData.append("v_aLivelloYellowRemaining", aLivelloYellowRemaining);
    formData.append("v_aLivelloRedRemaining", aLivelloRedRemaining);

    return axios.post(this.getUrlDomain("PrjWebUpdCheckExpire"), formData, { 'Content-Type': 'multipart/form-data' });

  }






  async getGMLevel(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("PrjWebGetGMLevels"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getCheckScadenze(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("PrjWebGetCheckExpire"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertClienti(user, id_azienda, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("PrjWebClientiInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insertDetail(ordine_dettagli, user, id_azienda, id_ordine) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebClientiInsDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDetail(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("PrjWebClientiGetDetailApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateDetail(ordine_dettagli, user, id_azienda, id_ordine, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);
    formData.append("id", id);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebClientiUpdDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteDetail(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("PrjWebClientiDelDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertOrdineOfferta(ordine_dettagli, user, file) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("attachment" ,  file);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebClientiInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initEditClienti(user, id_invoice, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("PrjWebClientiInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async updateOrdineOfferta(ordine_dettagli, user, file, id, remove_attachment) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("attachment" ,  file);
    formData.append("id" ,  id);
    formData.append("remove_attachment" ,  remove_attachment);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebClientiUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteOrdiniClienti(user, id_ordine) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_ordine);

    return axios.post(this.getUrlDomain("PrjWebClientiDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createProjFin(user, id, company) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("company", company);
    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("PrjWebCreateFinancial"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getResourcesOld(user, id_ordine, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_ordine", id_ordine);
    formData.append("id_azienda", id_azienda);


    return axios.post(this.getUrlDomain("PrjWebClientiGetResFroAssoc"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getResources(user, id_ordine) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_ordine", id_ordine);


    return axios.post(this.getUrlDomain("PrjWebClientiGetResFroAssoc"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateResAssoc(id_ordine, user_sel, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_ordine", id_ordine);
    formData.append("user_sel", user_sel);


    return axios.post(this.getUrlDomain("PrjWebClientiUpdResAssoc"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async loadBudget(user, v_dataScadDa, v_dataScadA, v_dateEmissDa, v_dateEmissA, curCompany) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("dateScadA", v_dataScadA);
    formData.append("dataScadDa", v_dataScadDa);
    formData.append("dateEmissDa", v_dateEmissDa);
    formData.append("dateEmissA", v_dateEmissA);
    formData.append("curCompany", curCompany);



    return axios.post(this.getUrlDomain("PrjWebLoadBudget"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APIOrdini({
  url: "http://emtool.local/public/api/smartbook/"
})
