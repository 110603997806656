import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIBanche {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getGruppi(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("InvoiceWebBancheGetGruppi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insertBanca(banca, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
  
    for ( var key in banca ) {
      formData.append(key, banca[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebBancheInsert"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async getBanca(id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);

    return axios.post(this.getUrlDomain("InvoiceWebBancheGetBanca2"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateBanca(banca, user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  
    for ( var key in banca ) {
      formData.append(key, banca[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebBancheUpdate"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteMultiBanca(user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  

    return axios.post(this.getUrlDomain("InvoiceWebBancheDelete"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIBanche({
  url: "http://emtool.local/public/api/smartbook/"
})
