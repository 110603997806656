<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_iva">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>Azienda</th>
                                        <th class='all'>Descrizione</th>
                                        <th class='all cl-header-valore'>Valore</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="5">
                                        </th>
                                    </tr>
                                </tfoot>


                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contclienti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetClienti" class="vclientisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetClienti" fixed style="position:fixed;">
                                Clienti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetClienti = !sheetClienti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchClienti">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredClienti" :key="item.companyid"
                                        @click="manageClickClienti(item)">

                                        <v-img alt="Clienti Logo" class="  " contain src="@/assets/custom_filter.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemCliente'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpagamenti">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetPagamenti">
                        <v-sheet class="text-center" height="430px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closePagamentiSheet' icon @click="sheetPagamenti = !sheetPagamenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_pagamenti">
                                Pagamenti
                            </div>

                            <v-list-item v-for="tile in allPayments" :key="tile.title"
                                @click="filterPagamento(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>





        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadDa" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <label style="font-weight:bold;margin-left:11px">Pagamenti</label>

                            <v-radio-group v-model="selPag">
                                <v-radio v-for="item in allPayments" :key="item.id" :label="item.title" :value="item.id"
                                    :id="item.id_elem"></v-radio>
                            </v-radio-group>


                        </v-col>

                    </v-row>






                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" />


                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-from" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupdateto" style="display:none">

            <template>
                <modal name="popupDateTo" :clickToClose="false" :width="300" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissA" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadA" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <label style="font-weight:bold;margin-left:11px">Pagamenti</label>

                            <v-radio-group v-model="selPag">
                                <v-radio v-for="item in allPayments" :key="item.id" :label="item.title" :value="item.id"
                                    :id="item.id_elem"></v-radio>
                            </v-radio-group>


                        </v-col>

                    </v-row>






                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-to" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" />


                                <v-img alt="" class="btn-reset-date-to" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-to" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/2recr_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain
                                    src="@/assets/btn_cancel3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm3_prjweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>





    </div>
</template>

<script>
import apiinvoice from "../utils/invoice/apiinvoice";
import apiiva from "../utils/iva/apiiva";

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */



export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/



        var pointerVue = this;

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");


        pointerVue.$root.$children[0].normalDiv1 = true;
        pointerVue.$root.$children[0].normalDiv2 = true;

        pointerVue.$root.$children[0].invoiceDiv1 = false;
        pointerVue.$root.$children[0].invoiceDiv2 = false;
        pointerVue.$root.$children[0].invoiceDiv3 = false;
        pointerVue.$root.$children[0].invoiceDiv4 = false;

        pointerVue.$root.$children[0].curTitle = "Iva";


        window.curSrcText = "";



        // inizializzazione valori
        window.dateEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");
        window.dateEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

        window.dateScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");
        window.dateScadA = window.$cookies.get("sel_filter_inv_date_scad_to");


        pointerVue.$root.$children[0].valDateFrom = window.$cookies.get("sel_filter_inv_date_scad_from");
        pointerVue.$root.$children[0].valDateTo = window.$cookies.get("sel_filter_inv_date_scad_to");


        $(document).ready(
            function () {

                $("body").off();

                // window.uname = window.$cookies.get("username");
                // var uname = window.uname;

                // const args = [uname, true, ['bar', 5], {
                //     foo: 'baz'
                // }];
                // try {
                //     console.log("entering user");
                //     window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                //     console.log("passed");

                // } catch (error) {
                //     // alert(error);
                // }

            }
        );

        $(document).ready(function () {



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



            $('body').on('click', '.selpagamento', function () {

                pointerVue.sheetPagamenti = true;

            });

            $('body').on('click', '.selaggiorna', function () {

                window.table.ajax.reload();

            });


            $('body').on('click', '.seldate', function (event) {
                console.log(event);

                //  pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");
                pointerVue.dataScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");

                //pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");
                pointerVue.dataEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");

                $(".contpopupdatefrom").show();


                pointerVue.selPag = 3;

                pointerVue.$modal.show('popupDateFrom');



            });










            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataScadDa = null;
                pointerVue.dataEmissDa = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);

                window.dateScadDa = pointerVue.dataScadDa;
                window.dateEmissDa = pointerVue.dataEmissDa;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                var val_data_scad_from = "";
                if (pointerVue.dataScadDa != null) {
                    val_data_scad_from = pointerVue.dataScadDa;
                }

                var val_data_emiss_from = "";
                if (pointerVue.dataEmissDa != null) {
                    val_data_emiss_from = pointerVue.dataEmissDa;
                }

                pointerVue.$root.$children[0].valDateFrom = val_data_scad_from;

                window.$cookies.set("sel_filter_inv_date_scad_from", val_data_scad_from, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_from", val_data_emiss_from, "9y");


                console.log("SEL PAG: ", pointerVue.selPag);

                var v_filter = "";

                if (pointerVue.selPag == 2) {
                    v_filter = "S";
                }
                else if (pointerVue.selPag == 3) {
                    v_filter = "N";
                }
                else if (pointerVue.selPag == 4) {
                    v_filter = "P";
                }


                window.curPayment = v_filter;







                window.table.ajax.reload();


            });








            $('body').on('click', '.seldate2', function (event) {
                console.log(event);

                //  pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");
                pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");

                //pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");
                pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

                $(".contpopupdateto").show();


                pointerVue.selPag = 3;

                pointerVue.$modal.show('popupDateTo');



            });


            $('body').on('click', '.btn-cancel-date-to', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateTo');

            });

            $('body').on('click', '.btn-reset-date-to', function (event) {
                console.log(event);

                pointerVue.dataScadA = null;
                pointerVue.dataEmissA = null;


            });


            $('body').on('click', '.btn-confirm-date-to', function (event) {
                console.log(event);

                window.dateScadA = pointerVue.dataScadA;
                window.dateEmissA = pointerVue.dataEmissA;

                pointerVue.$modal.hide('popupDateTo');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                var val_data_scad_to = "";
                if (pointerVue.dataScadA != null) {
                    val_data_scad_to = pointerVue.dataScadA;
                }

                var val_data_emiss_to = "";
                if (pointerVue.dataEmissA != null) {
                    val_data_emiss_to = pointerVue.dataEmissA;
                }

                pointerVue.$root.$children[0].valDateTo = val_data_scad_to;

                window.$cookies.set("sel_filter_inv_date_scad_to", val_data_scad_to, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_to", val_data_emiss_to, "9y");


                console.log("SEL PAG: ", pointerVue.selPag);

                var v_filter = "";

                if (pointerVue.selPag == 2) {
                    v_filter = "S";
                }
                else if (pointerVue.selPag == 3) {
                    v_filter = "N";
                }
                else if (pointerVue.selPag == 4) {
                    v_filter = "P";
                }


                window.curPayment = v_filter;







                window.table.ajax.reload();


            });








        });

        // setTimeout(() => {

        //     this.syncAziendaFiltro();

        //     this.syncClienti();

        // }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":


                    this.deleteRow();

                    break;

                case "btn_exit":
                    // router.push({
                    //     path: "/dash"
                    // });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editIva/0"
                    });
                    break;

                case "btn_edit":

                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editIva/" + ids
                    });

                    break;

                case "btn_back":
                    router.push({
                        path: "/filterIva"
                    });
                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        //var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({


        textSearch: "",

        selPag: null,

        dataScadA: null,
        dataScadDa: null,
        showPicker2: null,

        dataEmissA: null,
        dataEmissDa: null,
        showPicker3: null,
        showPicker4: null,

        allPayments: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_tutti"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Si',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_si"

            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'No',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_no"


            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Parziali',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_parziali"


            },


        ],


        sheetPagamenti: false,

        sheetClienti: false,
        searchClienti: "",

        clienti: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredClienti() {
            return _.orderBy(this.clienti.filter(item => {
                if (!this.searchClienti) return this.clienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },




    },

    methods: {

        btnConfirmSearch: function () {

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();


        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        getFirstDayMonth: function () {

            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var elements = firstDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },


        getLastDayMonth: function () {

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },




        manageClickClienti: async function (cliente) {

            console.log("CLI SEL: ", cliente);

            this.sheetClienti = false;

            window.curCustomer = cliente.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },

        filterPagamento: async function (status) {

            console.log(status);

            this.sheetPagamenti = false;

            var v_filter = "";

            if (status == "Si") {
                v_filter = "S";
            }
            else if (status == "No") {
                v_filter = "N";
            }
            else if (status == "Parziali") {
                v_filter = "P";
            }


            window.curPayment = v_filter;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();



        },

        syncClienti: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getClienti(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getClienti", res);

                this.clienti = res.data.customer;

                // try {

                //     this.aziende = res.data.company;

                // } catch (error) {
                //     console.log(error);
                // }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncAziendaFiltro: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            var v_company = window.$cookies.get("sel_filter_azienda_fatture");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getAziendaFiltro(
                v_token,
                v_company
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziendaFiltro", res);


                that.$root.$children[0].nomeAziendaSelezionata = res.data.azienda;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi",
                        width: 30

                    });


                }


                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-row-prjweb.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30


                    });

                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-prjweb.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30


                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    link: "/confOrariChoice",
                    // class: "inverted",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                    width: 35

                });





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;



            var filtri = ``;




            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {


                var v_token = window.$cookies.get("token");
                window.curCompany = window.$cookies.get("sel_filter_azienda_iva");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'fld_name',
                        orderable: false,
                        name: 'fld_name',
                        width: '5%'
                    },


                    {
                        data: 'descrizione',
                        orderable: false,
                        name: 'descrizione',
                        width: '8%'
                    },

                    {
                        data: 'valore',
                        orderable: false,
                        name: 'valore',
                        width: '2%'
                    },


                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("InvoiceWebGetIva") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curCustomer = window.curCustomer;
                            valori.curPayment = window.curPayment;

                            valori.dateScadDa = window.dateScadDa;
                            valori.dateScadA = window.dateScadA;

                            valori.dateEmissDa = window.dateEmissDa;
                            valori.dateEmissA = window.dateEmissA;

                            valori.curCompany = window.curCompany;

                            valori.curSrcText = window.curSrcText;


                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: ''
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;


                        }, 1000);





                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();



                        $('.p_valore').number(true, 2, ',', '.');




                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("InvoiceWebGetIva") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class='wrap_buttons'>

                    
                    ` + filtri + `

                    </div>

                </div>
                <div><img src='/prjweb/img/ricerca_prjweb.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });



                $('body').on('click', '.selcliente', function () {

                    console.log(("EVENTO CLICK SELCLIENTE"));

                    pointerVue.sheetClienti = true;

                });




                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_iva tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            //$("tr").removeClass("active-row");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });


                $(".selcambiaclientifornitori").on("ionChange", function (opt) {
                    console.log(opt);



                    if (this.value == 2) {


                        router.push({
                            path: "/fattureFornitori"
                        });



                    }


                });



                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);


                        window.id_par_sel = item.id;


                    }

                    if (!checked) {

                        $(v_row).removeClass("active-row");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            link: "/confOrariChoice",
                            //class: "inverted",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                            width: 35

                        },

                    ];

                }


                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-row-prjweb.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-prjweb.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30

                        },

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            link: "/confOrariChoice",
                            //class: "inverted",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                            width: 35

                        },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },


        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_prjweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_prjweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apiiva.deleteMultiIva(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteMultiIva", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.separator {
    min-height: 20px;
    float: left;
    border-right: 1px solid black;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}

.cont_iva #myTable {
    margin-right: 3px;
    width: 430px !important;
}

.cont_iva table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_iva table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_iva th {
    padding-left: 5px !important;
}

.cont_iva ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_iva .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_iva #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
}

.cont_iva div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_iva #myTable_wrapper {
    min-width: initial;
    min-width: 530px;
    max-width: 530px;
    width: 530px;
}


.cont_iva .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_iva .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}


.cont_iva .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_iva .checkall {
    width: 13px;
    height: 13px;
}

.cont_iva .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_iva .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
    width: 100% !important;
}

.cont_iva .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}


.cont_iva .cont-status {
    position: relative !important;
    overflow: hidden !important;
    width: 30px !important;
    height: 10px !important;
    margin-left: 22px !important;
}

.cont_iva .status {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    top: -11px;
    right: -11px;
}

.cont_iva .green {
    background: green;
}

.cont_iva .yellow {
    background: #ecec06;
}

.cont_iva .red {
    background: red;
}

.cont_iva .lightblue {
    background: lightblue;
}

.cont_iva .orange {
    background: orange;
}

.cont_iva .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_iva .text_right2 {
    width: 100% !important;
    text-align: right !important;
}


.cont_iva .selcambiaclientifornitori {
    max-width: 30px !important;
    min-height: 30px !important;
    margin-left: -5px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    background-image: url(/invoiceweb/img/change_invoiceweb2.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_iva .selcliente {
    max-width: 28px;
    min-height: 28px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_iva .selanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_iva .seleliminaanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_iva .selscadenziario {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_iva .selpagamento {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}







.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemCliente {
    margin-left: 15px;
}


.closePagamentiSheet {
    position: absolute;
    right: 0;
}

.c_title_pagamenti {
    text-align: left !important;
    margin-left: 44px !important;
}

.cont_iva div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}

.cont_iva .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.cont_iva .cl_valuta {
    font-weight: bold;
    text-align: center
}

.cont_iva .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_iva .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_iva .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_iva .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cl-header-valore {
    text-align: right;
    padding-right: 5px
}




@media screen and (max-width: 960px) {

    .cont_iva .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_iva #myTable {
        /* margin-top:54px; */
    }

    .cont_iva #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_iva .cont_buttons {
        /* max-width: 218px; */
        height: 40px;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0px;
    }

    .cont_iva .wrap_buttons {
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cont_iva .text_title {
        background-color: white;
        color: black;
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 9px;
        font-size: 10px;
        min-width: 50px;
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
    }



}

@media screen and (max-width: 1024px) {

    .cl-header-valore {}


    .contIcon {
        display: inherit;
    }

    .cont_iva .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_iva #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_iva #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }


    .cont_iva .cl_num {
        margin-left: -3px !important;
    }

    .cont_iva .cl_checkall {
        margin-left: 0px !important;
    }

    .cont_iva .cont-status {
        display: inline-flex;
    }

    .cont_iva .cl_valuta {
        display: inline;
    }

    .cont_iva .text_right {
        /* display: inline; */
    }

    .cont_iva .img_centered {
        display: inline;
    }


    .cont_iva .col_footer {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        color: white;
    }

    .cont_iva .col_footer_right {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        text-align: right;
        color: white;
    }


}
</style>
