import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIOrdiniFornitori {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;


  }

  async getInfoPratica(user, idpratica) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idpratica", idpratica);

    return axios.post(this.getUrlDomain("PrjWebAgilaGetInfoPrat"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateGMPratica(user, idpratica, costopratica, gmpratica) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idpratica", idpratica);
    formData.append("costopratica", costopratica);
    formData.append("gmpratica", gmpratica);

    return axios.post(this.getUrlDomain("PrjWebAgilaUpdGMPrat"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateGMLevels(user, daLivelloGreen, daLivelloYellow, aLivelloYellow, aLivelloRed) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_daLivelloGreen", daLivelloGreen);
    formData.append("v_daLivelloYellow", daLivelloYellow);
    formData.append("v_aLivelloYellow", aLivelloYellow);
    formData.append("v_aLivelloRed", aLivelloRed);

    return axios.post(this.getUrlDomain("PrjWebUpdGMLevels"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateCheckScadenze(user, daLivelloGreen, daLivelloYellow, aLivelloYellow, aLivelloRed, daLivelloGreenRemaining, daLivelloYellowRemaining, aLivelloYellowRemaining, aLivelloRedRemaining) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_daLivelloGreen", daLivelloGreen);
    formData.append("v_daLivelloYellow", daLivelloYellow);
    formData.append("v_aLivelloYellow", aLivelloYellow);
    formData.append("v_aLivelloRed", aLivelloRed);

    formData.append("v_daLivelloGreenRemaining", daLivelloGreenRemaining);
    formData.append("v_daLivelloYellowRemaining", daLivelloYellowRemaining);
    formData.append("v_aLivelloYellowRemaining", aLivelloYellowRemaining);
    formData.append("v_aLivelloRedRemaining", aLivelloRedRemaining);

    return axios.post(this.getUrlDomain("PrjWebUpdCheckExpire"), formData, { 'Content-Type': 'multipart/form-data' });

  }






  async getGMLevel(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("PrjWebGetGMLevels"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getCheckScadenze(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("PrjWebGetCheckExpire"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertFornitori(user, id_azienda, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("PrjWebSuppliersInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insertDetail(ordine_dettagli, user, id_azienda, id_ordine) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebSuppliersInsDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDetail(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("PrjWebSuppliersGetDetailApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateDetail(ordine_dettagli, user, id_azienda, id_ordine, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);
    formData.append("id", id);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebSuppliersUpdDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteDetail(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("PrjWebSuppliersDelDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertOrdineOfferta(ordine_dettagli, user, file) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("attachment" ,  file);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebSuppliersInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initEditFornitori(user, id_invoice, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("PrjWebSuppliersInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async updateOrdineOfferta(ordine_dettagli, user, file, id, remove_attachment) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("attachment" ,  file);
    formData.append("id" ,  id);
    formData.append("remove_attachment" ,  remove_attachment);

    for (var key in ordine_dettagli) {
      formData.append(key, ordine_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("PrjWebSuppliersUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteOrdiniFornitori(user, id_ordine) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_ordine);

    return axios.post(this.getUrlDomain("PrjWebSuppliersDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createProjFin(user, id, company) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("company", company);
    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("PrjWebCreateFinancial"), formData, { 'Content-Type': 'multipart/form-data' });

  }

}

export default new APIOrdiniFornitori({
  url: "http://emtool.local/public/api/smartbook/"
})
