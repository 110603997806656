import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISediOperative {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initDDL(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaSediOpInitiDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInfoAzienda(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaSediOpGetInfoAzApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async saveSedeOperativa(sede_operativa, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in sede_operativa) {
      formData.append(key, sede_operativa[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editSedeOperativa(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteSedeOperativa(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APISediOperative({
  url: "https://services.ebadge.it/public/api/"
})
