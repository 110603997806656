import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIProgettiFinanziari {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;


  }

  
  async deleteProgetti(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("PrjWebDelPrjFinApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditProgetto(user, id_invoice, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("PrjWebProgettoEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveProject(progetto, v_id) {

    let formData = new FormData();


    formData.append("v_id", v_id);
  
    for (var key in progetto) {
      formData.append(key, progetto[key]);
    }


    return axios.post(this.getUrlDomain("PrjWebProgettoSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIProgettiFinanziari({
  url: "http://emtool.local/public/api/smartbook/"
})
