<template>
    <div class="cont-table-perform-budget">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_perform_budget">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable">
                                <thead>
                                    <tr>

                                        <th class='all'>&nbsp;</th>
                                        <th class='all'>
                                            <div class="cl_tit_anno">Anno</div>
                                        </th>
                                        <th class='desktop'>Azienda</th>
                                        <th class='desktop'>Cliente</th>
                                        <th class='all'>Budget</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Gennaio</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Febbraio</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Marzo</th>
                                        <th class='all cl_title_qtr'>1 Qtr</th>
                                        <!-- <th class='desktop'>&nbsp;</th> -->
                                        <th class='all cl_title_and'>And.</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Aprile</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Maggio</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Giugno</th>
                                        <th class='all cl_title_qtr'>2 Qtr</th>
                                        <!-- <th class='desktop'>&nbsp;</th> -->
                                        <th class='all cl_title_and'>And.</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Luglio</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Agosto</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Settembre</th>
                                        <th class='all cl_title_qtr'>3 Qtr</th>
                                        <!-- <th class='desktop'>&nbsp;</th> -->
                                        <th class='all cl_title_and'>And.</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Ottobre</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Novembre</th>
                                        <th class='desktop' style="text-align:right;padding-right:4px">Dicembre</th>
                                        <th class='all cl_title_qtr'>4 Qtr</th>
                                        <th class='all cl_title_and'>And.</th>
                                        <th class='all cl_title_totale' style="text-align:right;padding-right:4px">
                                            Totale</th>
                                        <th class='all cl_title_and'>And.</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="27">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchAziende">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-icon aria-hidden="false">mdi-domain</v-icon>

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contclienti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetClienti" class="vclientisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetClienti" fixed style="position:fixed;">
                                Clienti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetClienti = !sheetClienti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchClienti">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredClienti" :key="item.companyid"
                                        @click="manageClickClienti(item)">

                                        <v-img alt="Clienti Logo" class="  " contain src="@/assets/custom_filter.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemCliente'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpopupbudgetprevisto" style="display:none">

            <template>
                <modal name="popupBudgetPrevisto" :clickToClose="false" :width="248" :height="505">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Budget Previsto

                        </v-col>



                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="padding-bottom:0px!important">

                            <ion-item id="itemTipoDocumento"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Anno </ion-label>

                                <ion-select id="anno_budget_prev" name="anno_budget_prev" class='anno_budget_prev'
                                    cancel-Text="Chiudi" done-Text="" :value="annoBudgetPrev" interface="action-sheet">

                                    <ion-select-option v-for="item in anni" :key="item.valore" :value='item.valore'>
                                        {{ item.descrizione }}</ion-select-option>

                                </ion-select>
                            </ion-item>

                        </v-col>


                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <div
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Budget Previsto 1
                                                Quarter</label>

                                            <vue-autonumeric id="primoQuarter" v-model="primoQtrBudgetPrev" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <div
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Budget Previsto 2
                                                Quarter</label>

                                            <vue-autonumeric id="secondoQuarter" v-model="secondoQtrBudgetPrev" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <div
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Budget Previsto 3
                                                Quarter</label>

                                            <vue-autonumeric id="terzoQuarter" v-model="terzoQtrBudgetPrev" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <div
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Budget Previsto 4
                                                Quarter</label>

                                            <vue-autonumeric id="quartoQuarter" v-model="quartoQtrBudgetPrev" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12" style="padding-bottom:0px!important;padding-top:0px!important">

                            <div
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Totale</label>

                                            <vue-autonumeric v-model="totalBudgetPrevisto" :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-budget-prev" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelBudgetPrev" />

                                <v-img alt="" class="btn-confirm-budget-prev" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Salva" @click="btnConfirmBudgetPrev" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




    </div>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apiperformance from "../utils/performance/apiperformance";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();
      


        var pointerVue = this;

        pointerVue.$root.$children[0].viewBudget = false;

        pointerVue.$root.$children[0].curDataOraLogin = "";


        pointerVue.$root.$children[0].scadenziarioDiv1 = false;
        pointerVue.$root.$children[0].scadenziarioDiv2 = false;
        pointerVue.$root.$children[0].scadenziarioDiv3 = false;

        pointerVue.$root.$children[0].normalDiv1 = true;
        pointerVue.$root.$children[0].normalDiv2 = true;

        pointerVue.$root.$children[0].invoiceDiv1 = false;
        pointerVue.$root.$children[0].invoiceDiv2 = false;
        pointerVue.$root.$children[0].invoiceDiv3 = false;
        pointerVue.$root.$children[0].invoiceDiv4 = false;

        pointerVue.$root.$children[0].curTitle = "Budget";

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        // var b = window.$cookies.get("b");
        // var $rotta = "";

        // valori.curYear = window.curYear;
        // valori.curCompany = window.curCompany;

        // valori default

        // console.log("ANNO CUR: ", dateTest.getFullYear());

        var dateTest = new Date();
        window.curYear = dateTest.getFullYear();

        window.curCompany = 7;

        window.curCustomer = "";

        console.log("CUR YEAR: ", window.curYear);
        console.log("CUR COMPANY: ", window.curCompany);

        $(document).ready(
            function () {

                $("body").off();

                // window.uname = window.$cookies.get("username");
                // var uname = window.uname;

                // const args = [uname, true, ['bar', 5], {
                //     foo: 'baz'
                // }];
                // try {
                //     console.log("entering user");
                //     window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                //     console.log("passed");

                // } catch (error) {
                //     // alert(error);
                // }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


        });

        setTimeout(() => {

            this.syncFilter();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":


                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":

                    this.isInsBudgetPrev = 1;

                    this.openPopup();


                    break;

                case "btn_edit":


                    this.isInsBudgetPrev = 0;


                    this.editBudgetPrevisto();


                    break;

                case "btn_back":
                    router.push({
                        path: "/filterusers"
                    });
                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        //var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        // pointerVue.setupButtons();

        this.hideShowButtons(false);

    },
    data: () => ({


        strYearBudget: "",

        isInsBudgetPrev: 0,


        annoBudgetPrev: null,
        primoQtrBudgetPrev: null,
        secondoQtrBudgetPrev: null,
        terzoQtrBudgetPrev: null,
        quartoQtrBudgetPrev: null,
        totalBudgetPrevisto: null,


        sheetAziende: false,
        searchAziende: "",

        sheetClienti: false,
        searchClienti: "",

        aziende: [],
        clienti: [],

        anni: [
            { valore: 2023, descrizione: "2023" },
            { valore: 2022, descrizione: "2022" },
            { valore: 2021, descrizione: "2021" },
            { valore: 2020, descrizione: "2020" }
        ],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.searchAziende) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.searchAziende.toLowerCase()));
            }), 'headline')
        },

        filteredClienti() {
            return _.orderBy(this.clienti.filter(item => {
                if (!this.searchClienti) return this.clienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },



    },


    methods: {


        editBudgetPrevisto: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiperformance.getBudgetPrevisto(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBudgetPrevisto", res);


                if (res.data.Result == "OK") {


                    that.annoBudgetPrev = res.data.Budget[0].year;
                    that.primoQtrBudgetPrev = res.data.Budget[0].budget_1_qtr;
                    that.secondoQtrBudgetPrev = res.data.Budget[0].budget_2_qtr;
                    that.terzoQtrBudgetPrev = res.data.Budget[0].budget_3_qtr;
                    that.quartoQtrBudgetPrev = res.data.Budget[0].budget_4_qtr;

                    var v_totale = parseFloat(res.data.Budget[0].budget_1_qtr) + parseFloat(res.data.Budget[0].budget_2_qtr) + parseFloat(res.data.Budget[0].budget_3_qtr) + parseFloat(res.data.Budget[0].budget_4_qtr);

                    console.log("TOTALE: ", v_totale);


                    that.totalBudgetPrevisto = v_totale;

                    $(".contpopupbudgetprevisto").show();

                    this.$modal.show('popupBudgetPrevisto');




                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.Errori,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );





        },


        btnCancelBudgetPrev: function () {


            this.$modal.hide('popupBudgetPrevisto');


        },


        btnConfirmBudgetPrev: function () {


            var errore = "";

            if (this.annoBudgetPrev == null) {
                errore = "Specificare l'anno";
            }

            if (errore == "") {
                if (this.primoQtrBudgetPrev == null) {
                    errore = "Specificare il valore per il 1o quarter";
                }
            }

            if (errore == "") {
                if (this.secondoQtrBudgetPrev == null) {
                    errore = "Specificare il valore per il 2o quarter";
                }
            }

            if (errore == "") {
                if (this.terzoQtrBudgetPrev == null) {
                    errore = "Specificare il valore per il 3o quarter";
                }
            }

            if (errore == "") {
                if (this.quartoQtrBudgetPrev == null) {
                    errore = "Specificare il valore per il 4o quarter";
                }
            }

            if (this.isInsBudgetPrev == 1) {


                if (errore == "") {

                    var elements = this.strYearBudget.split(",");

                    var is_present = 0;

                    for (var x = 0; x < elements.length; x++) {
                        if (parseInt(this.annoBudgetPrev) == parseInt(elements[x])) {
                            is_present = 1;
                        }
                    }

                    if (is_present == 1) {


                        errore = "L'anno " + this.annoBudgetPrev + " è già presente";

                    }


                }


            }






            if (errore == "") {


                console.log("ok");

                this.saveDataPopup();

            }


            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        saveDataPopup: async function () {

            var that = this;

            that.$modal.hide('popupBudgetPrevisto');

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiperformance.insDataBudgetPrev(
                v_token,
                that.annoBudgetPrev,
                that.primoQtrBudgetPrev,
                that.secondoQtrBudgetPrev,
                that.terzoQtrBudgetPrev,
                that.quartoQtrBudgetPrev,
                that.isInsBudgetPrev
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from insDataBudgetPrev", res);



                that.strYearBudget = res.data.str_year_budget;


                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                that.hideShowButtons(false);


                window.table.ajax.reload();




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        openPopup: async function () {


            this.annoBudgetPrev = null;
            this.primoQtrBudgetPrev = null;
            this.secondoQtrBudgetPrev = null;
            this.terzoQtrBudgetPrev = null;
            this.quartoQtrBudgetPrev = null;
            this.totalBudgetPrevisto = null;

            $(".contpopupbudgetprevisto").show();

            this.$modal.show('popupBudgetPrevisto');

        },




        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;


            window.table.ajax.reload();


        },

        manageClickClienti: async function (cliente) {

            console.log("CLI SEL: ", cliente);

            this.sheetClienti = false;

            window.curCustomer = cliente.companyid;


            window.table.ajax.reload();


        },

        syncFilter: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiperformance.initFilter(
                v_token,
                "prjweb"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initFilter", res);

                try {

                    this.strYearBudget = res.data.str_year_budget;

                    this.aziende = res.data.our_company;
                    this.clienti = res.data.customer;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tipo_budget = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;
                    tipo_budget = $(this).attr("tipo-budget");

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi Budget Previsto",
                        width: 30

                    });


                }


                if (tot == 1) {

                    if (tipo_budget == "Previsto") {

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-row-prjweb.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica Budget Previsto",
                            width: 30


                        });


                    }


                }


                // if (tot == 1) {

                //     pulsantis.push({
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/edit-row-invoiceweb.png",
                //         link: "/info",
                //         id: "btn_edit",
                //         disabled: false,
                //         title: "Modifica",
                //         width: 30


                //     });

                // }

                // if (tot > 0) {

                //     pulsantis.push({
                //         text: "Delete",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
                //         link: "/info",
                //         id: "btn_delete",
                //         disabled: false,
                //         title: "Elimina",
                //         width: 30


                //     });

                // }






                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;


            var filtri = `<img src='/prjweb/img/FiltroAzienda.png' style='border:0!important;' class='selazienda' title='Filtro Azienda' /><img src='/prjweb/img/customers.png' style='border:0!important;' class='selcliente' title='Filtro Cliente' /><div style="transform: translateY(-1px);margin-left:7px" title='Filtro Anno'><ion-select data-id="1" value="` + window.curYear + `" class="selanno" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Filtro Anno'><ion-select-option value='2023'>2023</ion-select-option><ion-select-option value='2022'>2022</ion-select-option><ion-select-option value='2021'>2021</ion-select-option></ion-select></div>`;

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                //var v_company = window.$cookies.get("sel_filter_azienda_utenti");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'val_anno',
                        orderable: false,
                        searchable: false,
                        name: 'val_anno',
                        width: '0.3%'
                    },

                    {
                        data: 'desc_our_cmp',
                        orderable: false,
                        searchable: false,
                        name: 'desc_our_cmp',
                        width: '3%'
                    },

                    {
                        data: 'desc_cust',
                        orderable: false,
                        searchable: false,
                        name: 'desc_cust',
                        width: '3%'
                    },

                    {
                        data: 'desc_budget',
                        orderable: false,
                        searchable: false,
                        name: 'desc_budget',
                        width: '1%'
                    },

                    {
                        data: 'gennaio',
                        orderable: false,
                        searchable: false,
                        name: 'gennaio',
                        width: '1%'
                    },

                    {
                        data: 'febbraio',
                        orderable: false,
                        searchable: false,
                        name: 'febbraio',
                        width: '1%'
                    },

                    {
                        data: 'marzo',
                        orderable: false,
                        searchable: false,
                        name: 'marzo',
                        width: '1%'
                    },

                    {
                        data: 'qtr_1',
                        orderable: false,
                        searchable: false,
                        name: 'qtr_1',
                        width: '0.3%'
                    },

                    {
                        data: 'andamento_qtr_1',
                        orderable: false,
                        searchable: false,
                        name: 'andamento_qtr_1',
                        width: '0.3%'
                    },

                    {
                        data: 'aprile',
                        orderable: false,
                        searchable: false,
                        name: 'aprile',
                        width: '1%'
                    },

                    {
                        data: 'maggio',
                        orderable: false,
                        searchable: false,
                        name: 'maggio',
                        width: '1%'
                    },

                    {
                        data: 'giugno',
                        orderable: false,
                        searchable: false,
                        name: 'giugno',
                        width: '1%'
                    },

                    {
                        data: 'qtr_2',
                        orderable: false,
                        searchable: false,
                        name: 'qtr_2',
                        width: '0.3%'
                    },

                    // {
                    //     data: 'andamento_qtr_2_qtr_3',
                    //     orderable: false,
                    //     searchable: false,
                    //     name: 'andamento_qtr_2_qtr_3',
                    //     width: '1%'
                    // },

                    {
                        data: 'andamento_qtr_2',
                        orderable: false,
                        searchable: false,
                        name: 'andamento_qtr_2',
                        width: '0.3%'
                    },

                    {
                        data: 'luglio',
                        orderable: false,
                        searchable: false,
                        name: 'luglio',
                        width: '1%'
                    },

                    {
                        data: 'agosto',
                        orderable: false,
                        searchable: false,
                        name: 'agosto',
                        width: '1%'
                    },

                    {
                        data: 'settembre',
                        orderable: false,
                        searchable: false,
                        name: 'settembre',
                        width: '1%'
                    },

                    {
                        data: 'qtr_3',
                        orderable: false,
                        searchable: false,
                        name: 'qtr_3',
                        width: '0.3%'
                    },

                    // {
                    //     data: 'andamento_qtr_3_qtr_4',
                    //     orderable: false,
                    //     searchable: false,
                    //     name: 'andamento_qtr_3_qtr_4',
                    //     width: '1%'
                    // },

                    {
                        data: 'andamento_qtr_3',
                        orderable: false,
                        searchable: false,
                        name: 'andamento_qtr_3',
                        width: '0.3%'
                    },

                    {
                        data: 'ottobre',
                        orderable: false,
                        searchable: false,
                        name: 'ottobre',
                        width: '1%'
                    },

                    {
                        data: 'novembre',
                        orderable: false,
                        searchable: false,
                        name: 'novembre',
                        width: '1%'
                    },

                    {
                        data: 'dicembre',
                        orderable: false,
                        searchable: false,
                        name: 'dicembre',
                        width: '1%'
                    },

                    {
                        data: 'qtr_4',
                        orderable: false,
                        searchable: false,
                        name: 'qtr_4',
                        width: '0.3%'
                    },

                    {
                        data: 'andamento_qtr_4',
                        orderable: false,
                        searchable: false,
                        name: 'andamento_qtr_4',
                        width: '0.7%'
                    },

                    {
                        data: 'v_tot',
                        orderable: false,
                        searchable: false,
                        name: 'v_tot',
                        width: '0.7%'
                    },

                    {
                        data: 'andamento_anno_precedente',
                        orderable: false,
                        searchable: false,
                        name: 'andamento_anno_precedente',
                        width: '0.7%'
                    },



                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("PrjWebPerformanceGetValuesApi") + '?username=' + v_token,

                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curYear = window.curYear;
                            valori.curCompany = window.curCompany;
                            valori.curCustomer = window.curCustomer;


                            window.valori = valori;
                            return valori;

                        }

                    },

                    /*    "scrollY":        "80vh",
                 "scrollCollapse": true, */

                    /*   ajax: {
                          url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                          data: function (valori) {
                              valori.length= 30;
                              valori.currDay = window.currDay;
                              valori.currDayTo = window.currDayTo;
                              valori.curStatus = window.curStatus;
                              valori.curPiano = window.curPiano;

                              return valori;

                          }

                      }, */

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    order: [[0, 'desc']],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        $('.p_totale, .p_totale_qtr').number(true, 3, ',', '.');


                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("PrjWebPerformanceGetValuesApi") + '?username=' + v_token,

                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    ` + filtri + `

                </div>
            `);




                $('body').on('blur', '#primoQuarter', function () {

                    console.log(("EVENTO CLICK SELPRIMOQTR"));

                    var v_tot = 0;

                    if (pointerVue.primoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.primoQtrBudgetPrev);

                    }

                    if (pointerVue.secondoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.secondoQtrBudgetPrev);

                    }

                    if (pointerVue.terzoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.terzoQtrBudgetPrev);

                    }

                    if (pointerVue.quartoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.quartoQtrBudgetPrev);

                    }

                    pointerVue.totalBudgetPrevisto = v_tot;


                });



                $('body').on('blur', '#secondoQuarter', function () {

                    console.log(("EVENTO CLICK SELPRIMOQTR"));

                    var v_tot = 0;

                    if (pointerVue.primoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.primoQtrBudgetPrev);

                    }

                    if (pointerVue.secondoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.secondoQtrBudgetPrev);

                    }

                    if (pointerVue.terzoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.terzoQtrBudgetPrev);

                    }

                    if (pointerVue.quartoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.quartoQtrBudgetPrev);

                    }

                    pointerVue.totalBudgetPrevisto = v_tot;


                });

                $('body').on('blur', '#terzoQuarter', function () {

                    console.log(("EVENTO CLICK SELPRIMOQTR"));

                    var v_tot = 0;

                    if (pointerVue.primoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.primoQtrBudgetPrev);

                    }

                    if (pointerVue.secondoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.secondoQtrBudgetPrev);

                    }

                    if (pointerVue.terzoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.terzoQtrBudgetPrev);

                    }

                    if (pointerVue.quartoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.quartoQtrBudgetPrev);

                    }

                    pointerVue.totalBudgetPrevisto = v_tot;


                });


                $('body').on('blur', '#quartoQuarter', function () {

                    console.log(("EVENTO CLICK SELPRIMOQTR"));

                    var v_tot = 0;

                    if (pointerVue.primoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.primoQtrBudgetPrev);

                    }

                    if (pointerVue.secondoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.secondoQtrBudgetPrev);

                    }

                    if (pointerVue.terzoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.terzoQtrBudgetPrev);

                    }

                    if (pointerVue.quartoQtrBudgetPrev != null) {

                        v_tot = v_tot + parseFloat(pointerVue.quartoQtrBudgetPrev);

                    }

                    pointerVue.totalBudgetPrevisto = v_tot;


                });








                $('body').on('ionChange', '.anno_budget_prev', function () {

                    console.log(("EVENTO CLICK SELANNOPOPUP"));

                    pointerVue.annoBudgetPrev = this.value;


                });



                $('body').on('click', '.cont_perform_budget tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });


                $('body').on('ionChange', '.selanno', function () {

                    console.log(("EVENTO CLICK SELANNO"));

                    window.curYear = this.value;

                    window.table.ajax.reload();

                });


                $('body').on('click', '.selazienda', function () {

                    console.log(("EVENTO CLICK SELAZIENDA"));

                    pointerVue.sheetAziende = true;

                });


                $('body').on('click', '.selcliente', function () {

                    console.log(("EVENTO CLICK SELCLIENTE"));

                    pointerVue.sheetClienti = true;

                });



                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });




                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });








                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);


                        window.id_par_sel = item.id;


                    }

                    if (!checked) {

                        $(v_row).removeClass("active-row");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi Budget Previsto",
                            width: 30

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     link: "/confOrariChoice",
                        //     //class: "inverted",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                        //     width: 35

                        // },

                    ];

                }


                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-row-prjweb.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-prjweb.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 27

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     link: "/confOrariChoice",
                        //     //class: "inverted",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                        //     width: 35

                        // },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                // var pulsantis = [

                //     {
                //         text: "Add",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                //         class: "inverted",
                //         link: "/settings",
                //         id: "btn_add",
                //         disabled: false,
                //     },

                //     {
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                //         class: "inverted",
                //         link: "/info",
                //         id: "btn_edit",
                //         disabled: false,
                //     },

                //     {
                //         text: "Delete",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                //         class: "inverted",
                //         link: "/info",
                //         id: "btn_delete",
                //         disabled: false,
                //     },

                //     {
                //         text: "Dashboard",
                //         icon: "mdi-home-circle",
                //         link: "/dash",
                //         id: "btn_exit",
                //         disabled: false,
                //     },

                // ];
                //window.App.$children[0].bnavbuttons = pulsantis;

                var pulsantis = [];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    // {
                    //     text: "Indietro",
                    //     icon: "mdi-arrow-left",
                    //     link: "/confOrariChoice",
                    //     //class: "inverted",
                    //     id: "btn_back",
                    //     disabled: false,
                    //     title: "Indietro",
                    //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    //     width: 35

                    // },


                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apiusers.deleteMultiUtente(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteMultiUtente", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont-table-perform-budget {
    overflow-x: auto !important;
    overflow-y: hidden !important;
    max-height: 152px !important
}



.cont-table-perform-budget .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_perform_budget .dataTable tbody tr,
.cont_perform_budget .dataTable tbody tr td {
    vertical-align: middle !important;
    padding-top: 5px !important;
}


.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

/* .cont_perform_budget #myTable {
    margin-right: 3px;
} */

.cont_perform_budget #myTable {
    margin-right: 3px !important;
    width: 1800px !important;
    margin-top: 67px !important;
}


.cont_perform_budget table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_perform_budget table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important;
}


.cont_perform_budget th {
    padding-left: 5px !important;
}

.cont_perform_budget ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_perform_budget .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_perform_budget #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    margin-top: 12px;
}

.cont_perform_budget input[type='search'] {
    visibility: hidden;
}


.cont_perform_budget div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

/* .cont_perform_budget #myTable_wrapper {
    padding-top: 0px;
} */

.cont_perform_budget #myTable_wrapper {
    min-width: initial !important;
    min-width: 1900px !important;
    max-width: 1900px !important;
    width: 1900px !important;
}


.cont_perform_budget .text_title {
    background-color: #0000b9;
    color: white;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_perform_budget .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}


.cont_perform_budget .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_perform_budget .checkall {
    width: 13px;
    height: 13px;
}

.cont_perform_budget .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_perform_budget .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 50px;
    padding-left: 10px;
    justify-content: inherit;

}

.cont_perform_budget div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}

.cont_perform_budget .val_status {
    width: 10px !important;
}

.text_right {
    text-align: right;
}

.text_right_month {
    text-align: right;
}

.text_right_totale {
    text-align: right;
    font-weight: bold;
}

.cont_perform_budget .selazienda {
    max-width: 22px;
    min-height: 22px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}

.cont_perform_budget .selcliente {
    max-width: 38px;
    min-height: 38px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemCliente {
    margin-left: 15px;
}



.v-input {
    font-size: 1.2em !important;
    padding-bottom: 0px !important;
}

.v-select {
    font-size: 1.2em !important;
}

.v-label {
    font-size: 1em !important;
}


.cont_perform_budget .selanno {
    max-width: 22px !important;
    min-height: 22px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 22px !important;
    background-repeat: no-repeat !important;
    background-image: url(/prjweb/img/filtro_anno.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.p_totale_qtr {
    font-weight: bold;
}

.img_centered_tot {
    text-align: center !important;
}

.img_centered {
    text-align: center !important;
}

.p_anno {}

.cl_title_qtr {
    text-align: right;
    padding-right: 4px
}

.cl_title_totale {
    text-align: right;
    padding-right: 6px
}


.cl_title_and {}

@media screen and (max-width: 960px) {

    .cont_perform_budget .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_perform_budget #myTable_filter {
        top: 56px;
        position: fixed;

    }

}


@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_perform_budget .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_perform_budget #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
        margin-top: 83px !important;
    }

    .cont_perform_budget #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        /* overflow-x: hidden !important; */
    }

    .cont_perform_budget .cl_num {
        margin-left: -2px !important;
    }

    .cont_perform_budget .cl_checkall {
        padding-left: 19px;
        padding-top: 2px;
    }

    .text_right_month {
        text-align: right;
        display: inline;
    }


    .text_right_totale {
        text-align: right;
        font-weight: bold;
        display: inline;
        font-size: 8px !important;
    }

    .img_centered_tot {
        display: inline !important;
    }

    .p_totale_qtr {
        font-weight: bold;
        font-size: 8px !important;
    }

    .p_anno {
        font-size: 8px;
    }

    .cl_tit_anno {
        text-align: right !important;
        font-size: 8px !important;
        margin-right: 4px !important;
    }

    .cl_title_qtr {
        text-align: right !important;
        padding-right: 4px !important;
        font-size: 8px !important;
    }

    .cl_title_and {
        font-size: 8px !important;
    }

    .cl_title_totale {
        text-align: right !important;
        padding-right: 7px !important;
        font-size: 8px !important;
    }


}
</style>
