<template>
    <v-container class="w-container v-overflow" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
            background-color: #ffed00;
            color: white;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>



            <v-row>
                <v-col cols="12" md="12" xs="12">

                    Foto

                    <div id="preview">

                    </div>

                    <v-col cols="12" md="12">

                        <span class="files">
                            <label style="margin-left:11px;font-size:11px"> </label>
                            <input type="file" id="input-file-logo" name="logo" accept=".png,.jpg,.jpeg" class="dropify"
                                data-max-width="6610" data-max-height="6200" />
                        </span>

                    </v-col>


                    <img id="imgPreview" />
                </v-col>
            </v-row>



            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="fldInpDescription" v-model="spese.description" label="Descrizione">
                    </v-text-field>

                </v-col>

            </v-row>





            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="fldPiva" v-model="spese.piva" label="Partita iva" maxlength="200">
                    </v-text-field>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field id="fldInpData" v-model="spese.date" label="Data" persistent-hint readonly
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="spese.date" no-title @input="showPicker = false" locale="it">
                        </v-date-picker>
                    </v-menu>


                </v-col>


            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="d_importo"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted cl_importo">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Importo</label>

                                    <vue-autonumeric id="fldInpAmount" v-model="spese.amount" :disabled="true" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


        </v-form>


        <div class="contpopupimporto">

            <template>
                <modal name="popupImporto" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Importo</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreImporto" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldImporto" id="fldImporto"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-importo" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-importo" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

    </v-container>
</template>

<script>
import { createWorker, PSM, OEM } from 'tesseract.js';
const worker = createWorker({
    logger: m => console.log(m),
});


import apispese from "../utils/spese/apispese";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";
import Vue from "vue";
import VModal from 'vue-js-modal';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        Vue.use(VModal, {
            dialog: true
        });

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        //console.log("AZIENDE: ", window.$cookies.get("gen_settings"));

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        this.$root.$children[0].initDivError("#d_importo", "Importo richiesto");


        if (this.$route.params.id > 0) {


            this.titolo = "Modifica Spesa";

            setTimeout(() => {
                this.syncSpesa();
            }, 100);

        }


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/spese"
                    });
                    break;

                case "btn_reco":
                    var hasImage = false;
                    try {

                        hasImage = $(".dropify-render img").attr("src").length;

                    } catch (error) {
                        console.log(error);
                    }
                    if (hasImage) {
                        this.recognize();

                    }
                    else {
                        this.$swal({
                            icon: "error",
                            text: "Per favore inserire un'immagine",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }


                    break;




                default:
                    break;
            }
        });

        $(document).ready(function () {


            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;
                    pointerVue.imageB64 = "";

                    pointerVue.spese.date = "";

                    pointerVue.setupButtons();

                    document.getElementById("imgPreview").src = "";

                });


            }, 100);

            $('#input-file-logo').on('change', async function () {


                $("#fldInpDescription").val("");
                $("#fldInpData").val("");
                $("#fldInpAmount").val("");



                pointerVue.attachRemove = false;

                const imageFile = document.querySelector('#input-file-logo').files[0];

                //Resize start
                var reader = new FileReader();
                reader.onload = function (e) {
                    var img = document.createElement("img");
                    img.onload = function (event) {

                        console.log(event);

                        // Dynamically create a canvas element
                        var canvas = document.createElement("canvas");

                        // var canvas = document.getElementById("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.mozImageSmoothingEnabled = false;
                        ctx.webkitImageSmoothingEnabled = false;
                        ctx.msImageSmoothingEnabled = false;
                        ctx.imageSmoothingEnabled = false;


                        var width = 600;
                        var height = 600;


                        // Actual resizing
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);


                        // Show resized image in preview element
                        var dataurl = canvas.toDataURL(imageFile.type);
                        document.getElementById("imgPreview").src = dataurl;
                    }
                    img.src = e.target.result;
                }

                setTimeout(() => {
                    pointerVue.setupButtons();
                }, 600);


                reader.readAsDataURL(imageFile);


                //Resize end

                /*           const result = await pointerVue.toBase64(imageFile);
          
                          console.log(result);
                          pointerVue.imageB64 = result;
          
                          document.getElementById("imgPreview").src = result; */


                //pointerVue.recognize();



            });

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                }

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });



            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');


                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.spese.amount = pointerVue.valoreImporto;

                $("#fldInpAmount").val(pointerVue.valoreImporto);

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });




        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {


        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        valoreImporto: null,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Spesa",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },

        spese: {},

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        select: null,
        checkbox: false,


    }),

    methods: {

        toDataURL: function (url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        },

        recognize: async function () {

            this.$root.$children[0].showProgress = true;


            setTimeout(() => {

                $(".v-progress-circular__info").text("Searching Date");

            }, 100);

            const img = document.getElementById('imgPreview');

            console.log(img);
            await worker.load();
            await worker.loadLanguage('ita');
            await worker.initialize('ita', OEM.LSTM_ONLY);
            await worker.setParameters({
                tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
            });

            var part = {
                rectangle: { top: 200, left: 0, width: 410, height: 200 },
            };

            console.log(part);

            setTimeout(() => {

                $(".v-progress-circular__info").text("Searching Total");

            }, 5000);

            const { data: { text } } = await worker.recognize(img);


            var tot = this.getTotal(text);

            console.log(tot);

            //this.result=text;

            //this.spese.amount = text;

            var acapo = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            document.getElementById("preview").innerHTML = acapo;

            console.log(text);





            this.$root.$children[0].showProgress = false;



        },


        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.pagamento.companyid = azienda.companyid;

        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save_update_data-prjweb.png",
                    //class: "inverted",
                    title: "Salva",
                    width: 30

                },


                {
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/save",
                    id: "btn_reco",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/scontrino_easyer2.png",
                    //class: "inverted",
                    title: "Riconosci",
                    width: 22

                },

                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                    width: 35


                },

                ];


                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            //  var that = this;


            var img = document.getElementById("imgPreview");

            console.log("IMG SRC: ", img.src);

            var errore = "";

            if (img.src == "") {
                errore = "Specificare un'allegato";
            }
            else {
                if (img.src.indexOf("image") == -1) {
                    errore = "Specificare un'allegato";

                }
            }

            if (errore == "") {

                if ($("#fldInpDescription").val() == "") {

                    errore = "Specificare la descrizione";

                }

            }


            if (errore == "") {

                if ($("#fldInpData").val() == "") {

                    errore = "Specificare la data";

                }

            }


            if (errore == "") {

                if ($("#fldInpAmount").val() == "") {

                    errore = "Specificare l'importo";

                }

            }



            if (errore == "") {

                this.saveData();

            }
            else {


                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        getUrlFolder: function (nome_file) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_file;

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            var id_rec = that.$route.params.id;

            var img = document.getElementById("imgPreview");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apispese.validateUpdateSpese(
                v_token,
                that.spese.date,
                that.spese.amount,
                that.$route.params.id
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validateUpdateSpese", res);

                if (res.data.Result == "OK") {


                    console.log("OK");

                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    var response = await apispese.updateSpese(
                        that.spese,
                        id_rec,
                        v_token,
                        img.src
                    ).then((res) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from updateSpese", res);

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/spese"
                            });

                        }, 200);

                    }).catch(err => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err);
                        var msg = err.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("ERrori", "Non è stato possibile salvare i dati");
                        console.log("response", response);

                    }

                    );




                }

                if (res.data.Result == "ERROR") {

                    console.log("ERROR");

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );







            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apispese.updateSpese(
            //     that.spese,
            //     id_rec,
            //     v_token,
            //     img.src
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from updateSpese", res);

            //     that.$swal({
            //         icon: "success",
            //         text: "Dati salvati correttamente",
            //         showConfirmButton: false,
            //         timer: 2000
            //     });

            //     setTimeout(() => {

            //         that.dialogMsg = false;
            //         that.setupButtons();

            //         router.push({
            //             path: "/spese"
            //         });

            //     }, 200);

            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("ERrori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );

        },

        syncSpesa: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apispese.getSpese(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSpese", res);

                this.spese = res.data.spese;

                if (res.data.spese.amount != null) {

                    this.$root.$children[0].setFieldActive("#d_importo");

                }

                var txt_image = this.getUrlFolder("public/easyer/" + res.data.spese.photo_file_name);

                $(".dropify-render img").attr("src", txt_image);
                $(".dropify-wrapper").addClass("has-preview");
                $(".dropify-preview").show("slow");

                this.toDataURL(txt_image, function (dataUrl) {
                    console.log('RESULT:', dataUrl)

                    $("#imgPreview").attr("src", dataUrl);

                })


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );




        },

        loadTipologie: async function () {

            console.log("ok");

            // var v_token = window.$cookies.get("token");

            // var that = this;

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apipagamento.getTipologie(
            //     v_token
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from getTipologie", res);

            //     try {

            //         this.tipologie = res.data.Result;

            //     } catch (error) {
            //         console.log(error);
            //     }

            // }).catch(err => {
            //         that.$root.$children[0].showProgress = false;
            //         console.log(err);
            //         var msg = err.response.data.Error;

            //         that.$swal({
            //             icon: "error",
            //             text: msg,
            //             showConfirmButton: false,
            //             timer: 8000
            //         });
            //         console.log("ERrori", "Non è stato possibile caricare i dati");
            //         console.log("response", response);

            //     }

            // );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {


    .cl_importo {
        padding-left: 11px !important;
    }

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}
</style>
