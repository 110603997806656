<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>



        <div class="row cont_tickets cont_ordini_clienti">
            <div class=" " style="margin:0 auto;">
                <div class="card">


                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>Codice</th>
                                        <th class='all' style="text-align:center">Tipo</th>
                                        <th class='all'>Cliente</th>
                                        <th class='desktop'>Note</th>
                                        <th class='desktop' style="text-align:center">All. Offerta</th>
                                        <th class='desktop' style="text-align:center">All. Ordine</th>
                                        <th class='desktop'>Nome</th>
                                        <th class='desktop'>Data Inizio</th>
                                        <th class='desktop'>Data Fine</th>
                                        <th class='all' style="text-align:center">Stato</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Totale</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Fatturato</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Rimanente</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Valuta</th>
                                        <th class='desktop' style="text-align:center">Gestito</th>
                                        <th class='desktop' style="text-align:center">Prj. Fin.</th>
                                        <th class='desktop' style="text-align:center">Ris. Ass.</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>
                                            <div class="div_n">&nbsp;</div>
                                        </th>


                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>Totale (€)</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer_right' id="v_tot_subtotal">0,00</th>

                                        <th class='desktop col_footer_right' id="v_tot_invoiced">0,00</th>

                                        <th class='desktop col_footer_right' id="v_tot_remaining">0,00</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                    </tr>
                                </tfoot>


                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contclienti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetClienti" class="vclientisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetClienti" fixed style="position:fixed;">
                                Clienti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetClienti = !sheetClienti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchClienti">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredClienti" :key="item.companyid"
                                        @click="manageClickClienti(item)">

                                        <v-img alt="Clienti Logo" class="  " contain src="@/assets/customers.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemCliente'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpagamenti">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetPagamenti">
                        <v-sheet class="text-center" height="430px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closePagamentiSheet' icon @click="sheetPagamenti = !sheetPagamenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_pagamenti">
                                Tipo
                            </div>

                            <v-list-item v-for="tile in allPayments" :key="tile.title" @click="filterPagamento(tile.id)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                    tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>





        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadDa" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <!-- <v-row>

                        <v-col cols="12" md="12">

                            <label style="font-weight:bold;margin-left:11px">Pagamenti</label>

                            <v-radio-group v-model="selPag">
                                <v-radio v-for="item in allPayments" :key="item.id" :label="item.title" :value="item.id"
                                    :id="item.id_elem"></v-radio>
                            </v-radio-group>


                        </v-col>

                    </v-row> -->






                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />


                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-from" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupdateto" style="display:none">

            <template>
                <modal name="popupDateTo" :clickToClose="false" :width="300" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissA" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadA" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <!-- <v-row>

                        <v-col cols="12" md="12">

                            <label style="font-weight:bold;margin-left:11px">Pagamenti</label>

                            <v-radio-group v-model="selPag">
                                <v-radio v-for="item in allPayments" :key="item.id" :label="item.title" :value="item.id"
                                    :id="item.id_elem"></v-radio>
                            </v-radio-group>


                        </v-col>

                    </v-row> -->






                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-to" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />


                                <v-img alt="" class="btn-reset-date-to" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-to" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <!-- <v-img alt="" src="@/assets/2recr_ricerca3.png" max-width="25" max-height="25" /> -->

                            <v-img alt="" src="@/assets/ricerca_prjweb.png" max-width="25" max-height="25" />


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopuperror" style="display:none">

            <template>
                <modal name="popupError" :clickToClose="false" :width="350" :height="480">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:140px">

                            <v-img alt="" src="@/assets/error.png" max-width="60" max-height="60" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="txtError" style="padding-left:10px;padding-right:10px;font-weight:bold;font-size:12px">
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-confirm-error" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmError" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupcalculator" style="display:none">

            <template>
                <modal name="popupCalculator" :clickToClose="false" :width="350" :height="303">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left:10px;margin-top:10px!important;font-weight:bold">

                            Totale Fatture

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Fatture Selezionate
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divFattSel"
                                style="width:100%;min-width:150px;max-width:150px;height:70px;text-align:right;overflow-y:auto;font-weight:bold">
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Subtotale (€)
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divSubSel"
                                style="font-weight:bold;width:100%;min-width:150px;max-width:150px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Totale Iva (€)
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divIvaSel"
                                style="font-weight:bold;width:100%;min-width:150px;max-width:150px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Totale (€)
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divTotSel"
                                style="font-weight:bold;width:100%;min-width:150px;max-width:150px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-cancel-calculator" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Chiudi" @click="btnCancelCalculator" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupdate" style="display:none">

            <template>
                <modal name="popupDate" :clickToClose="false" :width="300" :height="561">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissDa" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissA" no-title @input="showPicker2 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>





                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelFilterDate" />


                                <v-img alt="" class="btn-reset-date" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetFilterDate" />

                                <v-img alt="" class="btn-confirm-date" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmFilterDate" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="text-left">
            <v-bottom-sheet v-model="sheetTipo">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetTipo = !sheetTipo">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_prjweb.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Tipo
                    </div>
                    <v-list-item v-for="prj in tipi" :key="prj.idinvoice_tipo_documento"
                        @click="filterTipo(prj.idinvoice_tipo_documento)">

                        <v-list-item-avatar>

                            <v-avatar size="25px" tile>

                                <v-img alt="" contain :src="prj.img" max-width="25" max-height="25" />

                            </v-avatar>

                        </v-list-item-avatar>

                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.descrizione
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetStato">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStato = !sheetStato">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_prjweb.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Stato
                    </div>
                    <v-list-item v-for="prj in stati" :key="prj.id" @click="filterStato(prj.id)">

                        <v-list-item-avatar>

                            <v-avatar size="25px" tile>

                                <v-img alt="" contain :src="prj.img" :max-width="prj.width" :max-height="prj.width" />

                            </v-avatar>

                        </v-list-item-avatar>

                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.descrizione
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetGestione">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetGestione = !sheetGestione">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_prjweb.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Gestione
                    </div>
                    <v-list-item v-for="prj in gestione" :key="prj.id" @click="filterGestione(prj.id)">

                        <v-list-item-avatar>

                            <v-avatar size="25px" tile>

                                <v-img alt="" contain :src="prj.img" :max-width="prj.width" :max-height="prj.width" />

                            </v-avatar>

                        </v-list-item-avatar>


                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.descrizione
                        }}</v-list-item-title>

                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetProgettiFinanziari">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetProgettiFinanziari = !sheetProgettiFinanziari">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_prjweb.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Progetti Finanziari
                    </div>
                    <v-list-item v-for="prj in progetti_finanziari" :key="prj.id" @click="filterProjFin(prj.id)">

                        <v-list-item-avatar>

                            <v-avatar size="25px" tile>

                                <v-img alt="" contain :src="prj.img" :max-width="prj.width" :max-height="prj.width" />

                            </v-avatar>

                        </v-list-item-avatar>


                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.descrizione
                        }}</v-list-item-title>

                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="contpopupresourcesordcli" style="display:none">

            <template>
                <modal name="popupResourcesOrdCli" :clickToClose="false" :width="362" :height="462">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;display:flex">

                            <div>
                                Associazione Risorse
                            </div>

                            <div style="margin-left:138px">
                                {{ valTariffa }}
                            </div>

                        </v-col>



                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                style="width:400px">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="margin-left:10px;width:50px">&nbsp;</div>
                            <div style="font-weight:bold;width:200px;margin-left: 10px;">Cognome</div>
                            <div style="font-weight:bold;width:200px;margin-left: -14px;">Nome</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">


                            <v-list style="overflow-y:auto;max-height: 233px;min-height: 233px;padding-top:21px">

                                <v-list-tile v-for="el in filteredRisorse" :key="el.login">

                                    <div class="contSecCheck" style="display:flex!important;height:30px">

                                        <div style="margin-left:10px;width:50px;margin-top:-32px">
                                            <v-checkbox v-model="idResSel" multiple class="cl-checkbox selsec"
                                                :value="el.login" />

                                        </div>

                                        <div style="width:200px">
                                            {{ el.surname }}
                                        </div>

                                        <div style="width:200px">
                                            {{ el.name }}
                                        </div>


                                    </div>

                                </v-list-tile>


                            </v-list>


                        </v-col>


                    </v-row>





                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-permissions" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelAssocResources" />

                                <v-img alt="" class="btn-confirm-permissions" contain src="@/assets/btn_confirm3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Salva" @click="btnConfirmAssocResources" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupbudget" style="display:none">

            <template>
                <modal name="popupBudget" :clickToClose="false" :width="300" :height="289">

                    <v-row>
                        <div style="margin-top:20px!important;font-weight:bold;margin-left:20px">
                            Budget
                        </div>
                    </v-row>

                    <v-row style="display:flex;margin-top:20px">

                        <div style="font-weight:bold;width:115px;margin-left:21px;border-top:1px solid black">
                            1 Qtr Bdg (€)
                        </div>

                        <div style="font-weight:bold;width:115px;border-top:1px solid black">
                            1 Qtr Ord (€)
                        </div>

                        <div style="font-weight:bold;width:40px;text-align:center;border-top:1px solid black">
                            Stato
                        </div>

                    </v-row>

                    <v-row style="display:flex">

                        <div style="width:115px;margin-left:21px;border-top:1px solid black;">
                            {{ valBudget1Qtr }}
                        </div>

                        <div style="width:115px;border-top:1px solid black">
                            {{ valOrd1Qtr }}
                        </div>

                        <div style="width:40px;text-align:center;border-top:1px solid black">
                            <img :src="imgBudget1Qtr" style="width:12px;margin-top:3px" />
                        </div>

                    </v-row>

                    <v-row style="display:flex;margin-top:20px">

                        <div style="font-weight:bold;width:115px;margin-left:21px;border-top:1px solid black">
                            2 Qtr Bdg (€)
                        </div>

                        <div style="font-weight:bold;width:115px;border-top:1px solid black">
                            2 Qtr Ord (€)
                        </div>

                        <div style="font-weight:bold;width:40px;text-align:center;border-top:1px solid black">
                            Stato
                        </div>

                    </v-row>

                    <v-row style="display:flex">

                        <div style="width:115px;margin-left:21px;border-top:1px solid black">
                            {{ valBudget2Qtr }}
                        </div>

                        <div style="width:115px;border-top:1px solid black">
                            {{ valOrd2Qtr }}
                        </div>

                        <div style="width:40px;text-align:center;border-top:1px solid black">
                            <img :src="imgBudget2Qtr" style="width:12px;margin-top:3px" />
                        </div>

                    </v-row>


                    <v-row style="display:flex;margin-top:20px">

                        <div style="font-weight:bold;width:115px;margin-left:21px;border-top:1px solid black">
                            3 Qtr Bdg (€)
                        </div>

                        <div style="font-weight:bold;width:115px;border-top:1px solid black">
                            3 Qtr Ord (€)
                        </div>

                        <div style="font-weight:bold;width:40px;text-align:center;border-top:1px solid black">
                            Stato
                        </div>

                    </v-row>

                    <v-row style="display:flex">

                        <div style="width:115px;margin-left:21px;border-top:1px solid black">
                            {{ valBudget3Qtr }}
                        </div>

                        <div style="width:115px;border-top:1px solid black">
                            {{ valOrd3Qtr }}
                        </div>

                        <div style="width:40px;text-align:center;border-top:1px solid black">
                            <img :src="imgBudget3Qtr" style="width:12px;margin-top:3px" />
                        </div>

                    </v-row>

                    <v-row style="display:flex;margin-top:20px">

                        <div style="font-weight:bold;width:115px;margin-left:21px;border-top:1px solid black">
                            4 Qtr Bdg (€)
                        </div>

                        <div style="font-weight:bold;width:115px;border-top:1px solid black">
                            4 Qtr Ord (€)
                        </div>

                        <div style="font-weight:bold;width:40px;text-align:center;border-top:1px solid black">
                            Stato
                        </div>

                    </v-row>

                    <v-row style="display:flex">

                        <div style="width:115px;margin-left:21px;border-top:1px solid black">
                            {{ valBudget4Qtr }}
                        </div>

                        <div style="width:115px;border-top:1px solid black">
                            {{ valOrd4Qtr }}
                        </div>

                        <div style="width:40px;text-align:center;border-top:1px solid black">
                            <img :src="imgBudget4Qtr" style="width:12px;margin-top:3px" />
                        </div>

                    </v-row>

                    <v-row style="display:flex;margin-top:20px">

                        <div style="font-weight:bold;width:115px;margin-left:21px;border-top:1px solid black">
                            Totale Bdg (€)
                        </div>

                        <div style="font-weight:bold;width:115px;border-top:1px solid black">
                            Totale Ord (€)
                        </div>

                        <div style="font-weight:bold;width:40px;text-align:center;border-top:1px solid black">
                            Stato
                        </div>

                    </v-row>

                    <v-row style="display:flex">

                        <div style="width:115px;margin-left:21px;border-top:1px solid black">
                            {{ valBudgetTot }}
                        </div>

                        <div style="width:115px;border-top:1px solid black">
                            {{ valOrdinatoTot }}
                        </div>

                        <div style="width:40px;text-align:center;border-top:1px solid black">
                            <img :src="imgBudget" style="width:12px;margin-top:3px" />
                        </div>

                    </v-row>




                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-budget" contain src="@/assets/btn_cancel3_prjweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" title="Chiudi" @click="btnClosePopupBudget" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchAziende">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="Clienti Logo" class="  " contain src="@/assets/aziende-emt.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


    </div>
</template>

<script>
import apiinvoice from "../utils/invoice/apiinvoice";
import apiordini from "../utils/ordini/apiordini";
import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */



export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var pointerVue = this;






        // const numberFormat = new Intl.NumberFormat("it-IT");
        // console.log("NUMBER: ", numberFormat.format(123.45));



        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        pointerVue.$root.$children[0].normalDiv1 = false;
        pointerVue.$root.$children[0].normalDiv2 = false;

        pointerVue.$root.$children[0].invoiceDiv1 = true;
        pointerVue.$root.$children[0].invoiceDiv2 = true;
        pointerVue.$root.$children[0].invoiceDiv3 = true;
        pointerVue.$root.$children[0].invoiceDiv4 = true;

        pointerVue.$root.$children[0].calcolatorDiv1 = false;

        pointerVue.$root.$children[0].invoiceTitle = "Clienti";


        pointerVue.$root.$children[0].viewBudget = true;



        // calcolatrice
        window.totale_selezionato = 0;





        window.curProgFinId = "";


        window.numTotBanche = 0;
        window.numTotTipiPag = 0;
        window.numTotValute = 0;
        window.numTotIva = 0;
        window.numTotUDM = 0;
        window.numTotClienti = 0;
        window.numTotFornitori = 0;


        window.isUserDemo = 0;


        // inizializzazione valori

        var v_def_val = window.$cookies.get("set_def_value_filter_ordini");

        if (v_def_val == 1) {

            window.dateEmissDa = "";
            window.dateEmissA = "";
            window.dateScadDa = "";
            window.dateScadA = "";
            pointerVue.$root.$children[0].valDateFrom = null;
            pointerVue.$root.$children[0].valDateTo = null;
            window.curGestioneId = "N_NULL"; // faccio vedere i non gestiti
            window.curSrcText = "";
            window.curTipoId = "";
            window.curCustomer = "";
            window.curStatoId = "4"; // attivi e in scadenza
            window.curPayment = "0"; // default fatture non pagate e parziali



        }


        window.curCustomer = "-1"; // default tutti i clienti

        window.curCompany = window.$cookies.get("sel_filter_azienda_ordini");


        $(document).ready(
            function () {

                $("body").off();

                // window.uname = window.$cookies.get("username");
                // var uname = window.uname;

                // const args = [uname, true, ['bar', 5], {
                //     foo: 'baz'
                // }];
                // try {
                //     console.log("entering user");
                //     window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                //     console.log("passed");

                // } catch (error) {
                //     // alert(error);
                // }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });




            $('body').on('click', '.selpagamento', function () {

                pointerVue.sheetPagamenti = true;

            });

            $('body').on('click', '.selaggiorna', function () {

                $(".checkall").prop("checked", false);

                pointerVue.$root.$children[0].calcolatorDiv1 = false;

                window.totale_selezionato = 0;

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });


            // $('body').on('click', '.seldate', function (event) {
            //     console.log(event);

            //     //  pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");
            //     pointerVue.dataScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");

            //     //pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");
            //     pointerVue.dataEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");

            //     $(".contpopupdatefrom").show();


            //     pointerVue.selPag = 3;

            //     pointerVue.$modal.show('popupDateFrom');



            // });


            $('body').on('click', '.seldate', function (event) {
                console.log(event);


                pointerVue.dataEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");
                pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

                pointerVue.dataScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");
                pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");



                $(".contpopupdate").show();


                pointerVue.selPag = "5";

                pointerVue.$modal.show('popupDate');



            });






            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataScadDa = null;
                pointerVue.dataEmissDa = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);

                window.dateScadDa = pointerVue.dataScadDa;
                window.dateEmissDa = pointerVue.dataEmissDa;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                var val_data_scad_from = "";
                if (pointerVue.dataScadDa != null) {
                    val_data_scad_from = pointerVue.dataScadDa;
                }

                var val_data_emiss_from = "";
                if (pointerVue.dataEmissDa != null) {
                    val_data_emiss_from = pointerVue.dataEmissDa;
                }

                pointerVue.$root.$children[0].valDateFrom = val_data_scad_from;

                window.$cookies.set("sel_filter_inv_date_scad_from", val_data_scad_from, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_from", val_data_emiss_from, "9y");


                console.log("SEL PAG: ", pointerVue.selPag);

                var v_filter = "";

                if (pointerVue.selPag == 2) {
                    v_filter = "S";
                }
                else if (pointerVue.selPag == 3) {
                    v_filter = "N";
                }
                else if (pointerVue.selPag == 4) {
                    v_filter = "P";
                }


                window.curPayment = v_filter;



                $(".checkall").prop("checked", false);

                pointerVue.$root.$children[0].calcolatorDiv1 = false;

                window.totale_selezionato = 0;



                window.table.ajax.reload();


            });


            $('body').on('click', '.seldate2', function (event) {
                console.log(event);

                pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");

                pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

                $(".contpopupdateto").show();


                pointerVue.selPag = 3;

                pointerVue.$modal.show('popupDateTo');



            });


            $('body').on('click', '.btn-cancel-date-to', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateTo');

            });

            $('body').on('click', '.btn-reset-date-to', function (event) {
                console.log(event);

                pointerVue.dataScadA = null;
                pointerVue.dataEmissA = null;


            });


            $('body').on('click', '.btn-confirm-date-to', function (event) {
                console.log(event);

                window.dateScadA = pointerVue.dataScadA;
                window.dateEmissA = pointerVue.dataEmissA;

                pointerVue.$modal.hide('popupDateTo');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                var val_data_scad_to = "";
                if (pointerVue.dataScadA != null) {
                    val_data_scad_to = pointerVue.dataScadA;
                }

                var val_data_emiss_to = "";
                if (pointerVue.dataEmissA != null) {
                    val_data_emiss_to = pointerVue.dataEmissA;
                }

                pointerVue.$root.$children[0].valDateTo = val_data_scad_to;

                window.$cookies.set("sel_filter_inv_date_scad_to", val_data_scad_to, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_to", val_data_emiss_to, "9y");


                console.log("SEL PAG: ", pointerVue.selPag);

                var v_filter = "";

                if (pointerVue.selPag == 2) {
                    v_filter = "S";
                }
                else if (pointerVue.selPag == 3) {
                    v_filter = "N";
                }
                else if (pointerVue.selPag == 4) {
                    v_filter = "P";
                }


                window.curPayment = v_filter;



                $(".checkall").prop("checked", false);

                pointerVue.$root.$children[0].calcolatorDiv1 = false;

                window.totale_selezionato = 0;



                window.table.ajax.reload();


            });








        });

        setTimeout(() => {

            this.syncAziendaFiltro();

            this.syncClienti();

            this.syncAziende();



        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_scadenziario":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    window.$cookies.set("type_fatt_from", "Clienti", "9y");

                    router.push({
                        path: "/scadenziario/" + ids
                    });


                    break;

                case "btn_add":


                    console.log("N_TOT_BANCHE:", window.numTotBanche);
                    console.log("N_TOT_TIPI_PAG:", window.numTotTipiPag);
                    console.log("N_TOT_VALUTE:", window.numTotValute);
                    console.log("N_TOT_IVA:", window.numTotIva);
                    console.log("N_TOT_UDM:", window.numTotUDM);
                    console.log("N_TOT_CLIENTI:", window.numTotClienti);
                    console.log("N_TOT_FORNITORI:", window.numTotFornitori);

                    var msg_error = "";

                    if (window.numTotBanche == 0) {
                        msg_error = msg_error + "Inserire almeno una banca nella sezione Impostazioni > Banche";
                    }

                    if (window.numTotTipiPag == 0) {

                        if (msg_error.length > 0) {
                            msg_error = msg_error + "<br><br>";
                        }

                        msg_error = msg_error + "Inserire almeno un tipo pagamento nella sezione Impostazioni > Condizioni Pagamento";
                    }


                    if (window.numTotValute == 0) {

                        if (msg_error.length > 0) {
                            msg_error = msg_error + "<br><br>";
                        }

                        msg_error = msg_error + "Inserire almeno una valuta nella sezione Impostazioni > Valute";
                    }

                    if (window.numTotIva == 0) {

                        if (msg_error.length > 0) {
                            msg_error = msg_error + "<br><br>";
                        }

                        msg_error = msg_error + "Inserire almeno un valore di iva nella sezione Impostazioni > Iva";
                    }

                    if (window.numTotUDM == 0) {

                        if (msg_error.length > 0) {
                            msg_error = msg_error + "<br><br>";
                        }

                        msg_error = msg_error + "Inserire almeno un'unità di misura nella sezione Impostazioni > Unità di Misura";
                    }


                    if (window.numTotClienti == 0) {

                        if (msg_error.length > 0) {
                            msg_error = msg_error + "<br><br>";
                        }

                        msg_error = msg_error + "Inserire almeno un cliente nella sezione Impostazioni > Clienti/Fornitori";
                    }




                    if (msg_error.length == 0) {

                        console.log("ok1");

                        router.push({
                            path: "/editOrdineCliente/0"
                        });

                    }

                    if (msg_error.length > 0) {



                        $(".contpopuperror").show();

                        this.$modal.show('popupError');

                        setTimeout(() => {

                            $("#txtError").html(msg_error);

                        }, 150);

                    }


                    break;

                case "btn_edit":


                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editOrdineCliente/" + ids
                    });

                    break;

                case "btn_back":
                    router.push({
                        path: "/filterOrdiniClienti"
                    });
                    break;

                case "btn_calculator":

                    var list_fatt_sel = "";
                    var tot_sub = 0;
                    var tot_iva = 0;
                    var tot = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {


                            var v_row = $(this).parent().parent();

                            var item = window.table.row(v_row).data();

                            console.log("ROM SELECTED: ", item);

                            if (list_fatt_sel.length > 0) {
                                list_fatt_sel = list_fatt_sel + "<br>";
                            }
                            list_fatt_sel = list_fatt_sel + item.full_num_order;



                            var val = item.totale_calc;
                            var v_imp = val.toString().replace(".", "");
                            v_imp = v_imp.toString().replace(",", ".");
                            tot_sub = parseFloat(tot_sub) + parseFloat(v_imp);

                            val = item.totale_iva_calc;
                            v_imp = val.toString().replace(".", "");
                            v_imp = v_imp.toString().replace(",", ".");
                            tot_iva = parseFloat(tot_iva) + parseFloat(v_imp);


                            val = item.totale_pagare_calc;
                            v_imp = val.toString().replace(".", "");
                            v_imp = v_imp.toString().replace(",", ".");
                            tot = parseFloat(tot) + parseFloat(v_imp);


                        }

                    });


                    // divTotSel  

                    $(".contpopupcalculator").show();

                    this.$modal.show('popupCalculator');

                    setTimeout(() => {

                        $("#divFattSel").html(list_fatt_sel);

                        $("#divSubSel").html(this.formatMoney(tot_sub));

                        $("#divIvaSel").html(this.formatMoney(tot_iva));

                        $("#divTotSel").html(this.formatMoney(tot));


                    }, 200);

                    break;

                case "btn_create_prj_fin":

                    this.createPrjFin();

                    break;

                case "btn_assoc_ris":

                    this.getResources();

                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start_ = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        //var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        sheetAziende: false,
        searchAziende: "",

        imgBudget1Qtr: "",
        imgBudget2Qtr: "",
        imgBudget3Qtr: "",
        imgBudget4Qtr: "",


        imgBudget: "",

        valBudget1Qtr: "",
        valBudget2Qtr: "",
        valBudget3Qtr: "",
        valBudget4Qtr: "",
        valBudgetTot: "",
        valOrdinatoTot: "",

        valOrd1Qtr: "",
        valOrd2Qtr: "",
        valOrd3Qtr: "",
        valOrd4Qtr: "",

        valBudgetForCalc: 0,

        valTariffa: "",

        idResSel: [],

        risorse: [],

        aziende: [],


        search: "",


        sheetGestione: false,
        sheetProgettiFinanziari: false,

        sheetTipo: false,
        sheetStato: false,


        progetti_finanziari: [
            {
                id: "",
                descrizione: "Tutti",
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                width: 25
            },
            {
                id: "1",
                descrizione: "Si",
                img: 'https://app.emtool.eu/public/_lib/img/green_mark_ok.png',
                width: 13
            },
            {
                id: "2",
                descrizione: "No",
                img: 'https://app.emtool.eu/public/_lib/img/red_cross_icon_2.png',
                width: 13
            }
        ],




        gestione: [
            {
                id: "",
                descrizione: "Tutti",
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                width: 25
            },
            {
                id: "S",
                descrizione: "Si",
                img: 'https://app.emtool.eu/public/_lib/img/green_mark_ok.png',
                width: 13
            },
            {
                id: "N",
                descrizione: "No",
                img: 'https://app.emtool.eu/public/_lib/img/red_cross_icon_2.png',
                width: 13
            }
        ],

        tipi: [
            {
                idinvoice_tipo_documento: "",
                descrizione: "Tutti",
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
            },
            {
                idinvoice_tipo_documento: 1,
                descrizione: "Offerta",
                img: 'https://app.emtool.eu/public/_lib/img/icona-offerta-prjweb.png',
            },
            {
                idinvoice_tipo_documento: 2,
                descrizione: "Ordine",
                img: 'https://app.emtool.eu/public/_lib/img/icona-ordine-prjweb.png',
            }
        ],

        stati: [
            {
                id: "",
                descrizione: "Tutti",
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                width: 25
            },
            {
                id: 1,
                descrizione: "Attivi",
                img: 'https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__green_ball_10.png',
                width: 12
            },
            {
                id: 4,
                descrizione: "Attivi e In Scadenza",
                img: 'https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__yellow_ball_10.png',
                width: 12
            },

            {
                id: 2,
                descrizione: "In Scadenza",
                img: 'https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__yellow_ball_10.png',
                width: 12
            },
            {
                id: 3,
                descrizione: "Scaduti",
                img: 'https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__red_ball_10.png',
                width: 12
            }

        ],


        fattSel: "",

        textSearch: "",

        selPag: null,

        dataScadA: null,
        dataScadDa: null,
        showPicker2: null,

        dataEmissA: null,
        dataEmissDa: null,
        showPicker3: null,
        showPicker4: null,

        allPayments: [
            {
                img: '',
                title: 'Tutti',
                "id": 0,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_tutti"
            },

            {
                img: '',
                title: 'Offerta',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_si"

            },

            {
                img: '',
                title: 'Ordine',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_no"


            },


        ],


        sheetPagamenti: false,

        sheetClienti: false,
        searchClienti: "",

        clienti: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredClienti() {
            return _.orderBy(this.clienti.filter(item => {
                if (!this.searchClienti) return this.clienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },

        filteredRisorse() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.search) return this.risorse;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.searchAziende) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.searchAziende.toLowerCase()));
            }), 'headline')
        },



    },

    methods: {

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "prjweb"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                that.aziende = res.data.company;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            window.$cookies.set("sel_filter_azienda_ordini", azienda.companyid, "9y");

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            this.syncAziendaFiltro();

            this.hideShowButtons(false);


            window.table.ajax.reload();


        },


        btnClosePopupBudget: function () {

            this.$modal.hide('popupBudget');

        },

        formatMoney: function (number, decPlaces, decSep, thouSep) {

            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
                decSep = typeof decSep === "undefined" ? "." : decSep;
            thouSep = typeof thouSep === "undefined" ? "," : thouSep;
            var sign = number < 0 ? "-" : "";
            var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            var j = (j = i.length) > 3 ? j % 3 : 0;

            return sign +
                (j ? i.substr(0, j) + thouSep : "") +
                i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");


        },


        loadBudget: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiordini.loadBudget(
                v_token,
                window.dateScadDa,
                window.dateScadA,
                window.dateEmissDa,
                window.dateEmissA,
                window.curCompany
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadBudget", res);




                that.valBudget1Qtr = res.data.Budget1Qtr;
                that.valBudget2Qtr = res.data.Budget2Qtr;
                that.valBudget3Qtr = res.data.Budget3Qtr;
                that.valBudget4Qtr = res.data.Budget4Qtr;
                that.valBudgetTot = res.data.Budget;



                var v_tot_active = res.data.ValOrdinato;
                // $(".val_status").each(function (index) {

                //     console.log(index);

                //     if (($(this).attr("val-stato") == 1) || ($(this).attr("val-stato") == 2)) {

                //         v_tot_active = v_tot_active + parseFloat($(this).attr("val-totale"));

                //     }

                // });

                var numberFormat = new Intl.NumberFormat("it-IT", { minimumFractionDigits: 2 });

                if (v_tot_active > 0) {

                    var v_div_ord = v_tot_active / 4;


                    if (parseFloat(v_div_ord) >= parseFloat(res.data.Budget1QtrForCalc)) {

                        that.imgBudget1Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(v_div_ord) < parseFloat(res.data.Budget1QtrForCalc)) {

                        that.imgBudget1Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }


                    if (parseFloat(v_div_ord) >= parseFloat(res.data.Budget2QtrForCalc)) {

                        that.imgBudget2Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(v_div_ord) < parseFloat(res.data.Budget2QtrForCalc)) {

                        that.imgBudget2Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }


                    if (parseFloat(v_div_ord) >= parseFloat(res.data.Budget3QtrForCalc)) {

                        that.imgBudget3Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(v_div_ord) < parseFloat(res.data.Budget3QtrForCalc)) {

                        that.imgBudget3Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }

                    if (parseFloat(v_div_ord) >= parseFloat(res.data.Budget4QtrForCalc)) {

                        that.imgBudget4Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(v_div_ord) < parseFloat(res.data.Budget4QtrForCalc)) {

                        that.imgBudget4Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }



                    // var v_tmp = v_div_ord.toFixed(2);
                    // var v_tmp2 = numberFormat.format(v_tmp);

                    var v_tmp = v_div_ord;
                    var v_tmp2 = numberFormat.format(v_tmp);

                    console.log("ORD 1: ", v_tmp2);


                    // that.valOrd1Qtr = numberFormat.format(this.formatMoney(v_div_ord));
                    // that.valOrd2Qtr = this.formatMoney(v_div_ord);
                    // that.valOrd3Qtr = this.formatMoney(v_div_ord);
                    // that.valOrd4Qtr = this.formatMoney(v_div_ord);

                    that.valOrd1Qtr = numberFormat.format(v_div_ord);
                    that.valOrd2Qtr = numberFormat.format(v_div_ord);
                    that.valOrd3Qtr = numberFormat.format(v_div_ord);
                    that.valOrd4Qtr = numberFormat.format(v_div_ord);

                }
                else {

                    that.valOrd1Qtr = "0,00";
                    that.valOrd2Qtr = "0,00";
                    that.valOrd3Qtr = "0,00";
                    that.valOrd4Qtr = "0,00";


                    if (parseFloat(0) >= parseFloat(res.data.Budget1QtrForCalc)) {

                        that.imgBudget1Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(0) < parseFloat(res.data.Budget1QtrForCalc)) {

                        that.imgBudget1Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }


                    if (parseFloat(0) >= parseFloat(res.data.Budget2QtrForCalc)) {

                        that.imgBudget2Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(0) < parseFloat(res.data.Budget2QtrForCalc)) {

                        that.imgBudget2Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }


                    if (parseFloat(0) >= parseFloat(res.data.Budget3QtrForCalc)) {

                        that.imgBudget3Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(0) < parseFloat(res.data.Budget3QtrForCalc)) {

                        that.imgBudget3Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }

                    if (parseFloat(0) >= parseFloat(res.data.Budget4QtrForCalc)) {

                        that.imgBudget4Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                    }


                    if (parseFloat(0) < parseFloat(res.data.Budget4QtrForCalc)) {

                        that.imgBudget4Qtr = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                    }



                }



                that.valOrdinatoTot = numberFormat.format(v_tot_active);

                if (parseFloat(v_tot_active) >= parseFloat(res.data.BudgetForCalc)) {

                    that.imgBudget = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                }


                if (parseFloat(v_tot_active) < parseFloat(res.data.BudgetForCalc)) {

                    that.imgBudget = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                }


                setTimeout(() => {

                    $(".contpopupbudget").show();

                    that.$modal.show('popupBudget');

                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        saveResAssoc: async function () {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            var v_token = window.$cookies.get("token");

            var that = this;

            that.$modal.hide('popupResourcesOrdCli');


            console.log("ID RES SEL: ", that.idResSel);
            console.log("ID ORD: ", ids);

            var strLogin = "";

            for (var x = 0; x <= that.idResSel.length - 1; x++) {
                if (strLogin != "") {
                    strLogin = strLogin + "|";
                }
                strLogin = strLogin + that.idResSel[x];
            }

            console.log("IDS PERM SEL LOGIN:", strLogin);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiordini.updateResAssoc(
                ids,
                strLogin,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateResAssoc", res);

                that.$swal({
                    icon: "success",
                    text: "Risorse associate correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                that.hideShowButtons(false);


                window.table.ajax.reload();

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },

        btnCancelAssocResources: function () {

            this.$modal.hide('popupResourcesOrdCli');

        },

        btnConfirmAssocResources: function () {


            this.saveResAssoc();



        },


        getResources: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var v_token = window.$cookies.get("token");


            that.idResSel = [];


            var response = null;

            that.$root.$children[0].showProgress = true;

            response = await apiordini.getResources(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getResources", res);


                that.risorse = res.data.Result;

                that.valTariffa = "Tariffa/GG € " + res.data.TariffaGG;

                setTimeout(() => {

                    $(".contpopupresourcesordcli").show();

                    that.$modal.show('popupResourcesOrdCli');

                    setTimeout(() => {


                        if (res.data.LoginSel != null) {

                            var elements = res.data.LoginSel.split("|");

                            console.log("ELE SEL: ", elements);

                            that.idResSel = elements;

                        }





                    }, 200);




                }, 300);




            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );





        },



        filterTipo: function (tipoid) {


            console.log("PRJ TYPE SEL: ", tipoid);

            this.sheetTipo = false;

            window.curTipoId = tipoid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        filterStato: function (statoid) {


            console.log("PRJ STATO SEL: ", statoid);

            this.sheetStato = false;

            window.curStatoId = statoid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        filterGestione: function (gestioneid) {


            console.log("PRJ STATO SEL: ", gestioneid);

            this.sheetGestione = false;

            window.curGestioneId = gestioneid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },



        filterProjFin: function (progfinid) {


            console.log("PRJ FIN SEL: ", progfinid);

            this.sheetProgettiFinanziari = false;

            window.curProgFinId = progfinid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },








        btnCancelFilterDate: function () {

            this.$modal.hide('popupDate');

        },

        btnResetFilterDate: function () {

            this.dataEmissDa = null;
            this.dataEmissA = null;
            this.dataScadDa = null;
            this.dataScadA = null;

        },

        btnConfirmFilterDate: function () {



            console.log(event);

            window.dateScadA = this.dataScadA;
            window.dateEmissA = this.dataEmissA;
            window.dateScadDa = this.dataScadDa;
            window.dateEmissDa = this.dataEmissDa;

            this.$modal.hide('popupDate');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            var val_data_scad_to = "";
            if (this.dataScadA != null) {
                val_data_scad_to = this.dataScadA;
            }

            var val_data_emiss_to = "";
            if (this.dataEmissA != null) {
                val_data_emiss_to = this.dataEmissA;
            }

            this.$root.$children[0].valDateTo = val_data_scad_to;

            window.$cookies.set("sel_filter_inv_date_scad_to", val_data_scad_to, "9y");

            window.$cookies.set("sel_filter_inv_date_emiss_to", val_data_emiss_to, "9y");

            var val_data_scad_from = "";
            if (this.dataScadDa != null) {
                val_data_scad_from = this.dataScadDa;
            }

            var val_data_emiss_from = "";
            if (this.dataEmissDa != null) {
                val_data_emiss_from = this.dataEmissDa;
            }

            this.$root.$children[0].valDateFrom = val_data_scad_from;

            window.$cookies.set("sel_filter_inv_date_scad_from", val_data_scad_from, "9y");

            window.$cookies.set("sel_filter_inv_date_emiss_from", val_data_emiss_from, "9y");





            console.log("SEL PAG: ", this.selPag);

            var v_filter = "";

            if (this.selPag == 2) {
                v_filter = "S";
            }
            else if (this.selPag == 3) {
                v_filter = "N";
            }
            else if (this.selPag == 4) {
                v_filter = "P";
            }
            else if (this.selPag == 5) {
                v_filter = "NP";
            }


            window.curPayment = v_filter;


            $(".checkall").prop("checked", false);

            this.$root.$children[0].calcolatorDiv1 = false;

            window.totale_selezionato = 0;



            window.table.ajax.reload();

        },



        btnCancelCalculator: function () {

            this.$modal.hide('popupCalculator');

        },


        btnConfirmError: function () {

            this.$modal.hide('popupError');


        },

        btnConfirmSearch: function () {

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();


        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        getFirstDayMonth: function () {

            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var elements = firstDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },


        getLastDayMonth: function () {

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },




        manageClickClienti: async function (cliente) {

            console.log("CLI SEL: ", cliente);

            this.sheetClienti = false;

            window.curCustomer = cliente.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            $(".checkall").prop("checked", false);

            this.$root.$children[0].calcolatorDiv1 = false;

            window.totale_selezionato = 0;

            window.table.ajax.reload();


        },

        filterPagamento: async function (status) {

            console.log(status);

            this.sheetPagamenti = false;

            //var v_filter = "";

            // if (status == "Si") {
            //     v_filter = "S";
            // }
            // else if (status == "No") {
            //     v_filter = "N";
            // }
            // else if (status == "Parziali") {
            //     v_filter = "P";
            // }
            // else if (status == "No / Parziali") {
            //     v_filter = "NP";
            // }


            window.curPayment = status;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            $(".checkall").prop("checked", false);

            this.$root.$children[0].calcolatorDiv1 = false;

            window.totale_selezionato = 0;

            window.table.ajax.reload();



        },



        pdfFattura: async function (id_fatt) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.validatePdfFattura(
                v_token,
                id_fatt
            ).then(async (res) => {

                // that.$root.$children[0].showProgress = false;
                console.log("res from validatePdfFattura", res);


                if (res.data.Result == "OK") {



                    var response2 = await apiinvoice.createPdfFattura(
                        v_token,
                        id_fatt
                    ).then((res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from createPdfFattura", res2);


                        if (res2.data.Result == "OK") {


                            console.log(response2);

                            window.open(res2.data.val_url, "_blank");




                        }
                        else {

                            that.$root.$children[0].showProgress = false;

                            that.$swal({
                                icon: "error",
                                text: res2.data.Errore,
                                showConfirmButton: false,
                                timer: 2000
                            });


                        }





                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg2 = er2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg2,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile caricare i dati");
                        console.log("response", response);

                    }

                    );





                }
                else {

                    that.$root.$children[0].showProgress = false;

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },





        pdfAnticipo: async function (id_fatt) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.validatePdfAnticipo(
                v_token,
                id_fatt
            ).then(async (res) => {

                // that.$root.$children[0].showProgress = false;
                console.log("res from validatePdfAnticipo", res);


                if (res.data.Result == "OK") {



                    var response2 = await apiinvoice.createPdfAnticipo(
                        v_token,
                        id_fatt
                    ).then((res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from createPdfAnticipo", res2);


                        if (res2.data.Result == "OK") {


                            console.log(response2);

                            window.open(res2.data.val_url, "_blank");





                        }
                        else {

                            that.$root.$children[0].showProgress = false;

                            that.$swal({
                                icon: "error",
                                text: res2.data.Errore,
                                showConfirmButton: false,
                                timer: 2000
                            });


                        }





                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg2 = er2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg2,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile caricare i dati");
                        console.log("response", response);

                    }

                    );





                }
                else {

                    that.$root.$children[0].showProgress = false;

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncClienti: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getClienti(
                v_token,
                "prjweb"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getClienti", res);

                this.clienti = res.data.customer;

                // try {

                //     this.aziende = res.data.company;

                // } catch (error) {
                //     console.log(error);
                // }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncAziendaFiltro: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            var v_company = window.$cookies.get("sel_filter_azienda_ordini");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getAziendaFiltro(
                v_token,
                v_company
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziendaFiltro", res);

                that.$root.$children[0].nomeAziendaSelezionata = res.data.azienda;

                window.numTotBanche = res.data.n_banche;
                window.numTotTipiPag = res.data.n_tipi_pagamento;
                window.numTotValute = res.data.n_valute;
                window.numTotIva = res.data.n_iva;
                window.numTotUDM = res.data.n_udm;
                window.numTotClienti = res.data.n_customer;
                window.numTotFornitori = res.data.n_supplier;


                //that.tipi = res.data.filters_type_doc;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi",
                        width: 30

                    });


                }


                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-row-prjweb.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/risorse2.png",
                        link: "/info",
                        id: "btn_assoc_ris",
                        disabled: false,
                        title: "Associa Risorse",
                        width: 28
                    });


                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-prjweb.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 27

                    });

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/progetti_prjweb.png",
                        link: "/info",
                        id: "btn_create_prj_fin",
                        disabled: false,
                        title: "Crea Progetto Finanziario",
                        width: 30

                    });

                }


                // pulsantis.push({
                //     text: "Indietro",
                //     icon: "mdi-arrow-left",
                //     link: "/confOrariChoice",
                //     id: "btn_back",
                //     disabled: false,
                //     title: "Indietro",
                //     image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                //     width: 35

                // });





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },



        exportGridToPdf: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            var v_id_azienda = window.$cookies.get("sel_filter_azienda_fatture");
            var type_subject = 1;

            console.log("TOKEN: ", v_token);
            console.log("ID_AZIENDA: ", v_id_azienda);
            console.log("TYPE_SUBJECT: ", type_subject);
            console.log("FILTER CUSTOMER: ", window.curCustomer);
            console.log("FILTER PAYMENT: ", window.curPayment);
            console.log("FILTER DATA SCAD DA: ", window.dateScadDa);
            console.log("FILTER DATA SCAD A: ", window.dateScadA);
            console.log("FILTER DATA EMISS DA: ", window.dateEmissDa);
            console.log("FILTER DATA EMISS A: ", window.dateEmissA);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.exportGridPdf(
                v_token,
                v_id_azienda,
                type_subject,
                window.curCustomer,
                window.curPayment,
                window.dateScadDa,
                window.dateScadA,
                window.dateEmissDa,
                window.dateEmissA
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from exportGridPdf", res);

                window.open(res.data.url_file, "_blank");

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },








        setupGrid: function () {



            var pointerVue = this;

            var filtri = ``;




            filtri = `<img src='/prjweb/img/refresh-prjweb.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><div class='separator'></div><div style="transform: translateY(-1px);margin-left:7px" title='Clienti/Fornitori'><ion-select data-id="1" value="1" class="selcambiaclientifornitori" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Clienti/Fornitori'><ion-select-option value='1'>Clienti</ion-select-option><ion-select-option value='2'>Fornitori</ion-select-option></ion-select></div><div class='separator'></div><img src='/prjweb/img/aziende-emt.png' style='border:0!important;' class='selazienda' title='Azienda' /><div class='separator'></div><img src='/prjweb/img/customers.png' style='border:0!important;' class='selcliente' title='Clienti' /><div class='separator'></div><img src='/prjweb/img/icon copia.png' style='border:0!important;' class='seltipo' title='Tipo' /><div class='separator'></div><img src='/prjweb/img/calendar-prjweb.png' style='border:0!important;' class='seldate' title='Periodo' /><div class='separator'></div><img src='/prjweb/img/filtro-stato-emt.png' style='border:0!important;' class='selstato' title='Stato' /><div class='separator'></div><img src='/prjweb/img/storico-emt.png' style='border:0!important;' class='selgestione' title='Gestione' /><div class='separator'></div><img src='/prjweb/img/progetti_prjweb.png' style='border:0!important;' class='selprjfin' title='Progetto Finanziario' /><div class='separator'></div><img src='/prjweb/img/prestaz_prjweb.png' style='border:0!important;' class='selbudget' title='Budget' />`;





            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'row_id',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },



                    {
                        data: 'codice',
                        orderable: true,
                        name: 'codice',
                        width: '1%',
                    },

                    {
                        data: 'desc_type_doc',
                        orderable: false,
                        name: 'desc_type_doc',
                        width: '1%',
                    },

                    {
                        data: 'desc_customer',
                        orderable: true,
                        name: 'desc_customer',
                        width: '6%',
                    },

                    {
                        data: 'note',
                        orderable: true,
                        name: 'note',
                        width: '4%',
                    },

                    {
                        data: 'offer_attachment',
                        orderable: false,
                        name: 'offer_attachment',
                        width: '4%',
                    },

                    {
                        data: 'order_attachment',
                        orderable: false,
                        name: 'order_attachment',
                        width: '4%',
                    },

                    {
                        data: 'numero',
                        orderable: true,
                        name: 'numero',
                        width: '4%',
                    },

                    {
                        data: 'data_inizio',
                        orderable: true,
                        name: 'data_inizio',
                        width: '5%',
                    },

                    {
                        data: 'data_fine',
                        orderable: true,
                        name: 'data_fine',
                        width: '5%',
                    },

                    {
                        data: 'stato',
                        orderable: false,
                        name: 'stato',
                        width: '1%',
                    },



                    {
                        data: 'totale',
                        orderable: true,
                        name: 'totale',
                        width: '4%',
                    },

                    {
                        data: 'invoiced',
                        orderable: true,
                        name: 'invoiced',
                        width: '4%',
                    },

                    {
                        data: 'remaining',
                        orderable: true,
                        name: 'remaining',
                        width: '4%',
                    },


                    {
                        data: 'valuta_desc',
                        orderable: false,
                        name: 'valuta_desc',
                        width: '1%',
                    },

                    {
                        data: 'is_gestito',
                        orderable: false,
                        name: 'is_gestito',
                        width: '1%',
                    },

                    {
                        data: 'progetto_finanziario',
                        orderable: false,
                        name: 'progetto_finanziario',
                        width: '2%',
                    },


                    {
                        data: 'assoc_ris',
                        orderable: false,
                        name: 'assoc_ris',
                        width: '2%',
                    },



                ];





                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    "sDom": "lfrti",

                    data: window.dataset,


                    ajax: {
                        url: pointerVue.getUrlDomain("PrjWebGetCustomersOffersApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start_ = window.perpage;


                            valori.start_ = 0;
                            valori.length_ = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curCompany = window.curCompany;

                            valori.curCustomer = window.curCustomer;
                            valori.curPayment = window.curPayment;

                            valori.dateScadDa = window.dateScadDa;
                            valori.dateScadA = window.dateScadA;

                            valori.dateEmissDa = window.dateEmissDa;
                            valori.dateEmissA = window.dateEmissA;

                            valori.curSrcText = window.curSrcText;

                            valori.curTipoId = window.curTipoId;

                            valori.curStatoId = window.curStatoId;

                            valori.curGestioneId = window.curGestioneId;

                            valori.curProgFinId = window.curProgFinId;



                            window.valori = valori;
                            return valori;

                        }

                    },




                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: ''
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;


                        }, 2000);


                        var v_tot_active = 0;
                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).attr("val-stato") == 1) {

                                v_tot_active = v_tot_active + parseFloat($(this).attr("val-totale"));

                            }

                        });


                        console.log("TOT ACTIVE: ", v_tot_active);


                        pointerVue.$root.$children[0].valBudgetReale = v_tot_active;



                        setTimeout(() => {

                            console.log("V FORMAT 1: ", pointerVue.$root.$children[0].valBudgetPrevisto);
                            console.log("V FORMAT 2: ", pointerVue.$root.$children[0].valBudgetPrevisto.toString());

                            var v_tmp = pointerVue.$root.$children[0].valBudgetPrevisto.toString().split(".").join("");

                            console.log("V FORMAT 3: ", v_tmp);

                            v_tmp = v_tmp.replace(",", ".");

                            console.log("V FORMAT 4: ", v_tmp);

                            console.log("V FORMAT 5: ", parseFloat(v_tmp));


                            console.log("TOT BUDGET PREV: ", parseFloat(pointerVue.$root.$children[0].valBudgetPrevisto));
                            console.log("TOT BUDGET ACT: ", parseFloat(v_tot_active));


                            if (parseFloat(v_tot_active) >= parseFloat(v_tmp)) {

                                pointerVue.$root.$children[0].imgBudget = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_green_smile_20.png";

                            }


                            if (parseFloat(v_tot_active) < parseFloat(v_tmp)) {

                                pointerVue.$root.$children[0].imgBudget = "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_red_smile_20.png";

                            }





                        }, 700);


                        pointerVue.$root.$children[0].valBudgetReale = pointerVue.formatMoney(v_tot_active);



                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        $('.p_totale, .p_invoiced, .p_remaining, .p_totale_pagare').number(true, 3, ',', '.');

                        var subtotale = 0;
                        $(".p_totale").each(function () {
                            subtotale = subtotale + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });
                        $("#v_tot_subtotal").text(subtotale.toString().replace(".", ","));

                        var invoiced = 0;
                        $(".p_invoiced").each(function () {
                            invoiced = invoiced + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });
                        $("#v_tot_invoiced").text(invoiced.toString().replace(".", ","));

                        var remaining = 0;
                        $(".p_remaining").each(function () {
                            remaining = remaining + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });
                        $("#v_tot_remaining").text(remaining.toString().replace(".", ","));


                        var pagare = 0;
                        $(".p_totale_pagare").each(function () {
                            pagare = pagare + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });
                        $("#v_tot_pagato").text(pagare.toString().replace(".", ","));



                        $('#v_tot_subtotal, #v_tot_invoiced, #v_tot_remaining, #v_tot_pagato').number(true, 3, ',', '.');







                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start_ = window.start_;

                            $.ajax({
                                url: pointerVue.getUrlDomain("PrjWebGetCustomersOffersApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start_ += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class='wrap_buttons'>

                    
                    ` + filtri + `

                    </div>

                </div>
                <div><img src='/prjweb/img/ricerca_prjweb.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $('body').on('click', '.selazienda', function () {

                    console.log(("EVENTO CLICK SELAZIENDA"));

                    pointerVue.sheetAziende = true;

                });



                $(".seltipo").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetTipo = true;


                });

                $(".selstato").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetStato = true;


                });

                $(".selgestione").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetGestione = true;


                });




                $(".selprjfin").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetProgettiFinanziari = true;


                });



                $(".selbudget").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.loadBudget();


                });






                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.selcliente', function () {

                    console.log(("EVENTO CLICK SELCLIENTE"));

                    pointerVue.sheetClienti = true;

                });


                $('body').on('click', '.selpdffatture', function () {

                    console.log(("EVENTO CLICK SELPDFFATTURE"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfFattura(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }

                });




                $('body').on('click', '.selpdfanticipi', function () {

                    console.log(("EVENTO CLICK SELPDFANTICIPI"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfAnticipo(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }






                });



                $('body').on('click', '.selscadenziario', function () {

                    console.log(("EVENTO CLICK SELSCADENZIARIO"));

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    var errore = "";

                    if (tot == 0) {
                        errore = "Selezionare almeno una elemento";
                    }

                    if (errore == "") {

                        if (tot > 1) {
                            errore = "Selezionare solo un elemento";
                        }

                    }



                    if (errore == "") {

                        window.$cookies.set("type_fatt_from", "Clienti", "9y");

                        router.push({
                            path: "/scadenziario/" + ids
                        });

                    }
                    else {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                });



                $('body').on('click', '.selexportgridpdf', function () {
                    console.log("EVENT SELEXPORTGRIDPDF");


                    pointerVue.exportGridToPdf();

                });


                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_ordini_clienti tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                                //  pointerVue.updateCalculator(row_data.totale_pagare_calc, "+");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");

                                //  pointerVue.updateCalculator(row_data.totale_pagare_calc, "-");


                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                                // pointerVue.updateCalculator(row_data.totale_pagare_calc, "+");
                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                                // pointerVue.updateCalculator(row_data.totale_pagare_calc, "-");
                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });


                $(".selcambiaclientifornitori").on("ionChange", function (opt) {
                    console.log(opt);

                    $(".checkall").prop("checked", false);

                    pointerVue.$root.$children[0].calcolatorDiv1 = false;

                    window.totale_selezionato = 0;

                    window.$cookies.set("set_def_value_filter_ordini", 0, "9y");

                    if (this.value == 2) {


                        router.push({
                            path: "/ordiniFornitori"
                        });



                    }


                });



                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW SEL: ", item.totale_pagare_calc);

                        window.id_par_sel = item.id;


                        // pointerVue.updateCalculator(item.totale_pagare_calc, "+");


                    }

                    if (!checked) {

                        var item2 = window.table.row(v_row).data();

                        console.log("ITEM ROW DESEL: ", item2.totale_pagare_calc);

                        $(v_row).removeClass("active-row");


                        // pointerVue.updateCalculator(item2.totale_pagare_calc, "-");



                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },





        updateCalculatorGlobal: function () {

            var tot = 0;

            window.totale_selezionato = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    var v_row = $(this).parent().parent();

                    var item = window.table.row(v_row).data();

                    console.log("ROM SELECTED: ", item);


                    var val = item.totale_pagare_calc;

                    var v_imp = val.toString().replace(".", "");
                    v_imp = v_imp.toString().replace(",", ".");


                    window.totale_selezionato = parseFloat(window.totale_selezionato) + parseFloat(v_imp);


                }

            });

            if (tot > 1) {

                this.$root.$children[0].calcolatorDiv1 = true;

                setTimeout(() => {

                    $("#vTotCalc").html(this.formatMoney(window.totale_selezionato));

                }, 200);



            }
            else {

                this.$root.$children[0].calcolatorDiv1 = false;


            }



            // if (tot > 0) {




            // }


            // if (tot == 0) {


            //     this.$root.$children[0].calcolatorDiv1 = false;

            // }

        },

        updateCalculator: function (val, op) {

            var v_imp = val.toString().replace(".", "");
            v_imp = v_imp.toString().replace(",", ".");


            if (op == "+") {

                window.totale_selezionato = parseFloat(window.totale_selezionato) + parseFloat(v_imp);

            }

            if (op == "-") {

                window.totale_selezionato = parseFloat(window.totale_selezionato) - parseFloat(v_imp);

            }

            console.log("CALC TOT. ", window.totale_selezionato);

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });


            console.log("TOT SEL. ", tot);

            if (tot > 1) {

                this.$root.$children[0].calcolatorDiv1 = true;

                setTimeout(() => {

                    $("#vTotCalc").html(this.formatMoney(window.totale_selezionato));

                }, 200);



            }
            else {

                this.$root.$children[0].calcolatorDiv1 = false;


            }




        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                        //     width: 35

                        // },

                    ];

                }


                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-prjweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-row-prjweb.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-prjweb.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 27

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-prjweb.png",
                        //     width: 35

                        // },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        createPrjFin: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);
            console.log("AZI SEL:", window.curCompany);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response = await apiordini.createProjFin(
                v_token,
                ids,
                window.curCompany
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createProjFin", res);

                that.$swal({
                    icon: "success",
                    text: "Progetti creati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_prjweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_prjweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apiordini.deleteOrdiniClienti(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteOrdiniClienti", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_ordini_clienti .dataTable tbody tr,
.cont_ordini_clienti .dataTable tbody tr td {
    vertical-align: middle !important;
    padding-top: 5px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.contpopupresourcesordcli .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.separator {
    min-height: 20px;
    float: left;
    border-right: 1px solid black;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}

.cont_ordini_clienti #myTable {
    margin-right: 3px;
    width: 1700px !important;
}

.cont_ordini_clienti table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_ordini_clienti table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important;
    vertical-align: middle;
}

.cont_ordini_clienti th {
    padding-left: 5px !important;
}

.cont_ordini_clienti ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_ordini_clienti .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
    /* overflow-x: auto; */
}

.cont_ordini_clienti #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;

}

.cont_ordini_clienti div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_ordini_clienti #myTable_wrapper {
    min-width: initial;
    min-width: 1800px;
    max-width: 1800px;
    width: 1800px;
    /* overflow-x: auto; */
}


.cont_ordini_clienti .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_ordini_clienti .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}


.cont_ordini_clienti .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_ordini_clienti .checkall {
    width: 13px;
    height: 13px;
}

.cont_ordini_clienti .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_ordini_clienti .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
    width: 100% !important;
}

.cont_ordini_clienti .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}


.cont_ordini_clienti .cont-status {
    position: relative !important;
    overflow: hidden !important;
    width: 30px !important;
    height: 10px !important;
    margin-left: 22px !important;
}

.cont_ordini_clienti .status {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    top: -11px;
    right: -11px;
}

.cont_ordini_clienti .green {
    background: green;
}

.cont_ordini_clienti .yellow {
    background: #ecec06;
}

.cont_ordini_clienti .red {
    background: red;
}

.cont_ordini_clienti .lightblue {
    background: lightblue;
}

.cont_ordini_clienti .orange {
    background: orange;
}

.cont_ordini_clienti .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_ordini_clienti .text_right2 {
    width: 100% !important;
    text-align: right !important;
}


.cont_ordini_clienti .selcambiaclientifornitori {
    max-width: 30px !important;
    min-height: 30px !important;
    margin-left: -5px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    background-image: url(/prjweb/img/change_prjweb.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 0px !important;
}

.cont_ordini_clienti .selcliente {
    max-width: 34px;
    min-height: 34px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -3px;

}

.cont_ordini_clienti .selanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .seleliminaanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .selscadenziario {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}



.cont_ordini_clienti .seltipo {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .seldate {
    max-width: 26px;
    min-height: 26px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -7px;
}

.cont_ordini_clienti .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_ordini_clienti .selaggiorna {
    max-width: 23px;
    min-height: 23px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .selpagamento {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemCliente {
    margin-left: 15px;
}


.closePagamentiSheet {
    position: absolute;
    right: 0;
}

.c_title_pagamenti {
    text-align: left !important;
    margin-left: 44px !important;
}

.cont_ordini_clienti div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}

.cont_ordini_clienti .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.cont_ordini_clienti .cl_valuta {
    font-weight: bold;
    text-align: center
}

.cont_ordini_clienti .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_ordini_clienti .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_ordini_clienti .val_status {
    width: 10px !important;
}





.cont_ordini_clienti .selpdffatture {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .selpdfanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}


.cont_ordini_clienti .silinvfattcust {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .sendmailalertcli {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .selexportgridpdf {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ordini_clienti .selcopiafatt {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cl-div-hid {
    display: none;
}

.cont_ordini_clienti .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_ordini_clienti .selstato {
    max-width: 24px;
    min-height: 24px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 2px;
}

.cont_ordini_clienti .selgestione {
    max-width: 27px;
    min-height: 27px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    /* margin-right: 14px !important; */
    margin-top: 2px;
}

.cont_ordini_clienti .selprjfin {
    max-width: 27px;
    min-height: 27px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    /* margin-right: 14px !important; */
    margin-top: 2px;
}

.cont_ordini_clienti .selbudget {
    max-width: 27px;
    min-height: 27px;
    /* margin-left: 2px; */
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
    margin-top: 2px;
}



.cont_ordini_clienti .font-row-grid-datetime {
    font-size: 10px !important;
    width: 67px !important;
}

.cont_ordini_clienti {
    overflow-x: auto !important;
}



.cont_ordini_clienti .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}


.cl-tot-header {
    text-align: right;
    padding-right: 5px
}


.cl-radio i {
    font-size: 13px !important;
}

.cl-div-hid {
    display: none;
}

.cl-date {
    font-size: 10px;
    color: gray;
}


@media screen and (max-width: 960px) {

    .cont_ordini_clienti .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_ordini_clienti #myTable {
        /* margin-top:54px; */
    }

    .cont_ordini_clienti #myTable_filter {
        top: 56px;
        position: fixed;

    }

    /* .cont_ordini_clienti #myTable_wrapper {
  
    } */

    .cont_ordini_clienti .cont_buttons {
        max-width: 300px;
        height: 40px;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0px;
    }

    .cont_ordini_clienti .wrap_buttons {
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cont_ordini_clienti .text_title {
        background-color: white;
        color: black;
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 9px;
        font-size: 10px;
        min-width: 50px;
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .cont_ordini_clienti .seldate {
        max-width: 26px;
        min-height: 26px;
        margin-left: 1px;
        color: black !important;
        padding: 0px 0px;
        min-width: 14px !important;
        text-indent: -119px;
        background-size: 19px;
        background-repeat: no-repeat;
        z-index: 2;
        border: none !important;
        cursor: pointer;
        margin-top: -17px;
    }

    .cont_ordini_clienti .img_centered {
        width: 100% !important;
        text-align: center !important;
        min-width: 36px;
    }



}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_ordini_clienti .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_ordini_clienti #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_ordini_clienti #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;

    }


    .cont_ordini_clienti .cl_num {
        margin-left: -3px !important;
    }

    .cont_ordini_clienti .cl_checkall {
        margin-left: 19px !important;
    }

    .cont_ordini_clienti .cont-status {
        display: inline-flex;
    }

    .cont_ordini_clienti .cl_valuta {
        display: inline;
    }

    .cont_ordini_clienti .text_right {
        display: inline;
    }

    /* .cont_ordini_clienti .img_centered {
        display: inline;
    } */


    .cont_ordini_clienti .col_footer {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        color: white;
    }

    .cont_ordini_clienti .col_footer_right {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        text-align: right;
        color: white;
    }

    .cl-tot-header {
        text-align: right;
        padding-right: 5px
    }

    .cl-tot-header:after {
        content: ' (€)';
    }

    .font-row-grid-datetime {
        font-size: 10px !important;
        width: 100px !important;
        display: inline-table !important;
    }



}
</style>
