<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">
        <v-row>
            <v-col cols="12">
            </v-col>



            <v-col v-if="isPwdChangedOnce > 0 && viewClienti == 1" cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/vediOrdiniClienti">

                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/icona-ordine-prjweb.png"
                                transition="scale-transition" />

                        </v-btn>
                        <div class="btnText">Ordini Clienti</div>
                    </template>
                    <span>Ordini Clienti</span>
                </v-tooltip>


            </v-col>




            <v-col v-if="isPwdChangedOnce > 0 && viewFornitori == 1" cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/vediOrdiniFornitori">

                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/icona-offerta-prjweb.png"
                                transition="scale-transition" />

                        </v-btn>
                        <div class="btnText">Ordini Fornitori</div>
                    </template>
                    <span>Ordini Fornitori</span>
                </v-tooltip>

            </v-col>




            <v-col v-if="isPwdChangedOnce > 0 && viewProgetti == 1" cols="6" class="mb-15">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/filterProgettiFinanziari">
                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/progetti_prjweb.png"
                                transition="scale-transition" />

                        </v-btn>
                        <div class="btnText">Progetti Finanziari</div>
                    </template>
                    <span>Progetti Finanziari</span>
                </v-tooltip>

            </v-col>



        </v-row>
    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";

export default {
    name: "HelloWorld",

    mounted: function () {

        this.$root.$children[0].checkLogout();


        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");
        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");

        this.isAgila = window.$cookies.get("is_agila");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }

        this.$root.$children[0].normalDiv1 = true;
        this.$root.$children[0].normalDiv2 = true;

        this.$root.$children[0].invoiceDiv1 = false;
        this.$root.$children[0].invoiceDiv2 = false;
        this.$root.$children[0].invoiceDiv3 = false;
        this.$root.$children[0].invoiceDiv4 = false;

        this.$root.$children[0].calcolatorDiv1 = false;


       // var namesurname = window.$cookies.get("namesurname");

       // this.$root.$children[0].curTitle = namesurname;

        if (this.isSU == 0) {

            this.viewProgetti = this.getEnableConf("prj_fin");
            this.viewProgettiMaster = this.getEnableConf("prj_master");
            this.viewOreTask = this.getEnableConf("ore_task");
            this.viewAttivita = this.getEnableConf("attivita");
            this.viewAssocRis = this.getEnableConf("assoc_ris_task");
            this.viewPrat = this.getEnableConf("prat_agila");
            this.viewClienti = this.getEnableConf("ordini_clienti");
            this.viewFornitori = this.getEnableConf("ordini_fornitori");
            this.viewLivGM = this.getEnableConf("livello_gm");
            this.viewCheckScad = this.getEnableConf("check_scadenze");
            this.viewCDC = this.getEnableConf("cdc");
            this.viewTipoCDC = this.getEnableConf("tipo_cdc");
            this.viewGruppiMail = this.getEnableConf("gruppi_mail_prjweb");
            this.viewMail = this.getEnableConf("invio_mail_prjweb");
            this.viewProfilo = this.getEnableConf("profilo_prjweb");
            this.viewUtenti = this.getEnableConf("utenti_prjweb");


        }
        else {

            this.viewProgetti = 1;
            this.viewProgettiMaster = 1;
            this.viewOreTask = 1;
            this.viewAttivita = 1;
            this.viewAssocRis = 1;
            this.viewPrat = 1;
            this.viewClienti = 1;
            this.viewFornitori = 1;
            this.viewLivGM = 1;
            this.viewCheckScad = 1;
            this.viewCDC = 1;
            this.viewTipoCDC = 1;
            this.viewGruppiMail = 1;
            this.viewMail = 1;
            this.viewProfilo = 1;
            this.viewUtenti = 1;

        }




        this.getLastLogin();



    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },

    methods: {

        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "prjweb"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },

        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompany(token)
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;

                        // window.$cookies.set("user_models", res.data.Models, "9y");



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },



    },


    data: () => ({

        // isPwdChangedOnce: 0,

        // isSU: 0,

        viewProgetti: 0,
        viewProgettiMaster: 0,
        viewOreTask: 0,
        viewAttivita: 0,
        viewAssocRis: 0,
        viewPrat: 0,
        viewClienti: 0,
        viewFornitori: 0,
        viewLivGM: 0,
        viewCheckScad: 0,
        viewCDC: 0,
        viewTipoCDC: 0,
        viewGruppiMail: 0,
        viewMail: 0,
        viewProfilo: 0,
        viewUtenti: 0,


        isPwdChangedOnce: 0,

        viewGestioneUtenti: 0,
        viewEditTimesheet: 0,
        viewEditDisease: 0,
        viewEditHolidaysPermits: 0,
        viewTsFornitore: 0,
        viewVerTs: 0,
        viewClientiFornitori: 0,
        viewRisorse: 0,
        viewVerTsEbadge: 0,

        isCMBAdmin: 0,
        hasResAssociated: 0,

        isAgila: 0,
        isAdmin: 0,
        isSU: 0,
        isCustSupp: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
