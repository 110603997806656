var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"w-container",staticStyle:{"padding-top":"59px"},attrs:{"id":"conf_settings_firma"}},[_c('v-row',[_c('v-col',{staticClass:"mainCont",staticStyle:{"min-width":"100vw","position":"fixed","left":"0","padding-left":"0px"}},[_c('div',{staticClass:"mainTitle",staticStyle:{"background-color":"#ffed00","color":"white","font-weight":"bold","padding":"4px","font-size":"10px","min-width":"100vw"}},[_vm._v(_vm._s(_vm.titolo))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_coord_x"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}},[_vm._v("Coordinata Box X")]),_c('vue-autonumeric',{attrs:{"disabled":true,"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0'
                                }},model:{value:(_vm.impostazioni.coord_box_x),callback:function ($$v) {_vm.$set(_vm.impostazioni, "coord_box_x", $$v)},expression:"impostazioni.coord_box_x"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_coord_y"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}},[_vm._v("Coordinata Box Y")]),_c('vue-autonumeric',{attrs:{"disabled":true,"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0'
                                }},model:{value:(_vm.impostazioni.coord_box_y),callback:function ($$v) {_vm.$set(_vm.impostazioni, "coord_box_y", $$v)},expression:"impostazioni.coord_box_y"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1)],1),_c('div',{staticClass:"contpopupcoordx"},[[_c('modal',{attrs:{"name":"popupCoordX","clickToClose":false,"width":300,"height":180}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"15px","margin-left":"8px"}},[_vm._v("Coordinata Box X")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",staticStyle:{"cursor":"pointer"},attrs:{"id":"d_coord_x_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldCoordXFirma",attrs:{"options":{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        },"id":"fldCoordXFirma"},model:{value:(_vm.coordXFirma),callback:function ($$v) {_vm.coordXFirma=$$v},expression:"coordXFirma"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-img',{staticClass:"btn-cancel-coord-x",staticStyle:{"cursor":"pointer","color":"black","margin-right":"10px"},attrs:{"alt":"","contain":"","src":require("@/assets/btn_cancel3_prjweb.png"),"max-width":"30","max-height":"30","transition":"scale-transition"}}),_c('v-img',{staticClass:"btn-confirm-coord-x",staticStyle:{"cursor":"pointer"},attrs:{"alt":"","contain":"","src":require("@/assets/btn_confirm3_prjweb.png"),"max-width":"30","max-height":"30","transition":"scale-transition"}})],1)])],1)],1)]],2),_c('div',{staticClass:"contpopupcoordy"},[[_c('modal',{attrs:{"name":"popupCoordY","clickToClose":false,"width":300,"height":180}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"15px","margin-left":"8px"}},[_vm._v("Coordinata Box Y")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",staticStyle:{"cursor":"pointer"},attrs:{"id":"d_coord_x_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldCoordYFirma",attrs:{"options":{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        },"id":"fldCoordYFirma"},model:{value:(_vm.coordYFirma),callback:function ($$v) {_vm.coordYFirma=$$v},expression:"coordYFirma"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-img',{staticClass:"btn-cancel-coord-y",staticStyle:{"cursor":"pointer","color":"black","margin-right":"10px"},attrs:{"alt":"","contain":"","src":require("@/assets/btn_cancel3_prjweb.png"),"max-width":"30","max-height":"30","transition":"scale-transition"}}),_c('v-img',{staticClass:"btn-confirm-coord-y",staticStyle:{"cursor":"pointer"},attrs:{"alt":"","contain":"","src":require("@/assets/btn_confirm3_prjweb.png"),"max-width":"30","max-height":"30","transition":"scale-transition"}})],1)])],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }