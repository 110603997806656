import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from "../../vuecommon/profile.vue";
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import editSpesa from './components/editSpesa.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import reservate from './components/reservate.vue'
import firma from './components/firma.vue'
import sendMessage from './components/sendMessage.vue'
import spese from './components/spese.vue'
import editSpesaDetails from './components/editSpesaDetails.vue'
import approvazione from './components/approvazione.vue'
import editSpesaDetailsAdmin from './components/editSpesaDetailsAdmin.vue'
import fattureClienti from './components/fattureClienti.vue'
import filterFatture from './components/filterFatture.vue'
import fattureFornitori from './components/fattureFornitori.vue'
import banche from './components/banche.vue'
import filterBanche from './components/filterBanche.vue'
import editBanca from './components/editBanca.vue'
import scadenziario from './components/scadenziario.vue'
import fatturazioneAutomatica from './components/fatturazioneAutomatica.vue'
import editFatturaCliente from './components/editFatturaCliente.vue'
import testTable from './components/testTable.vue'
import editFatturaFornitore from './components/editFatturaFornitore.vue'
import mailGruppo from './components/mailGruppo.vue'
import editMailGruppo from './components/editMailGruppo.vue'
import confInvioMail from './components/confInvioMail.vue'
import filterPerformance from './components/filterPerformance.vue'
import performanceFatturazione from './components/performanceFatturazione.vue'
import vediFattureClienti from './components/vediFattureClienti.vue'
import vediFattureFornitori from './components/vediFattureFornitori.vue'
import filterCondizioniPagamento from './components/filterCondizioniPagamento.vue'
import condizioniPagamento from './components/condizioniPagamento.vue'
import editCondizioniPagamento from './components/editCondizioniPagamento.vue'
import filterValute from './components/filterValute.vue'
import valute from './components/valute.vue'
import editValuta from './components/editValuta.vue'
import filterIva from './components/filterIva.vue'
import iva from './components/iva.vue'
import editIva from './components/editIva.vue'
import filterUnitaDiMisura from './components/filterUnitaDiMisura.vue'
import unitaDiMisura from './components/unitaDiMisura.vue'
import editUnitaDiMisura from './components/editUnitaDiMisura.vue'
import configNotifiche from './components/configNotifiche.vue'
import company from './components/company.vue'
import editCompany from './components/editCompany.vue'
import vediPerformanceFatturazione from './components/vediPerformanceFatturazione.vue'
import filterOrdiniClienti from './components/filterOrdiniClienti.vue'
import ordiniClienti from './components/ordiniClienti.vue'
import filterOrdiniFornitori from './components/filterOrdiniFornitori.vue'
import ordiniFornitori from './components/ordiniFornitori.vue'
import ordiniClientiAgila from './components/ordiniClientiAgila.vue'
import filterOrdiniClientiAgila from './components/filterOrdiniClientiAgila.vue'
import editOrdiniClientiAgila from './components/editOrdiniClientiAgila.vue'
import livelliGM from './components/livelliGM.vue'
import editOrdineCliente from './components/editOrdineCliente.vue'
import checkScadenze from './components/checkScadenze.vue'
import filterProgettiFinanziari from './components/filterProgettiFinanziari.vue'
import progettiFinanziari from './components/progettiFinanziari.vue'
import editOrdineFornitore from './components/editOrdineFornitore.vue'
import budgetPerformancePrevisto from './components/budgetPerformancePrevisto.vue'
import budgetPerformanceRisultati from './components/budgetPerformanceRisultati.vue'
import vediOrdiniClienti from './components/vediOrdiniClienti.vue'
import vediOrdiniFornitori from './components/vediOrdiniFornitori.vue'
import editProgettoFinaziario from './components/editProgettoFinaziario.vue'

import loginTest from './components/loginTest.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }
        ,
        {
            path: '/editSpesa/:id',
            name: 'editSpesa',
            component: editSpesa,
            props: true
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props: true
        },



        {
            path: '/settings',
            name: 'settings',
            component: settings
        },


        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial
        }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/firma',
            name: 'firma',
            component: firma
        }

        ,
        {
            path: '/sendMessage',
            name: 'sendMessage',
            component: sendMessage
        }

        ,
        {
            path: '/spese',
            name: 'spese',
            component: spese
        }

        ,
        {
            path: '/editSpesaDetails/:id',
            name: 'editSpesaDetails',
            component: editSpesaDetails,
            props: true
        }

        ,
        {
            path: '/approvazione',
            name: 'approvazione',
            component: approvazione
        }


        ,
        {
            path: '/editSpesaDetailsAdmin/:id',
            name: 'editSpesaDetailsAdmin',
            component: editSpesaDetailsAdmin,
            props: true
        }

        ,
        {
            path: '/fattureClienti',
            name: 'fattureClienti',
            component: fattureClienti
        }

        ,
        {
            path: '/filterFatture',
            name: 'filterFatture',
            component: filterFatture
        }

        ,
        {
            path: '/fattureFornitori',
            name: 'fattureFornitori',
            component: fattureFornitori
        }

        ,
        {
            path: '/banche',
            name: 'banche',
            component: banche
        }

        ,
        {
            path: '/filterBanche',
            name: 'filterBanche',
            component: filterBanche
        }

        ,
        {
            path: '/editBanca/:id',
            name: 'editBanca',
            component: editBanca,
            props: true
        }

        ,
        {
            path: '/scadenziario/:id',
            name: 'scadenziario',
            component: scadenziario,
            props: true
        }


        ,
        {
            path: '/fatturazioneAutomatica',
            name: 'fatturazioneAutomatica',
            component: fatturazioneAutomatica
        }

        ,
        {
            path: '/editFatturaCliente/:id',
            name: 'editFatturaCliente',
            component: editFatturaCliente,
            props: true
        }


        ,
        {
            path: '/testTable',
            name: 'testTable',
            component: testTable
        }

        ,
        {
            path: '/editFatturaFornitore/:id',
            name: 'editFatturaFornitore',
            component: editFatturaFornitore,
            props: true
        }

        ,
        {
            path: '/mailGruppo',
            name: 'mailGruppo',
            component: mailGruppo
        }

        ,
        {
            path: '/editMailGruppo/:id',
            name: 'editMailGruppo',
            component: editMailGruppo,
            props: true
        }

        ,
        {
            path: '/confInvioMail',
            name: 'confInvioMail',
            component: confInvioMail
        }

        ,
        {
            path: '/filterPerformance',
            name: 'filterPerformance',
            component: filterPerformance
        }

        ,
        {
            path: '/performanceFatturazione',
            name: 'performanceFatturazione',
            component: performanceFatturazione
        }

        ,
        {
            path: '/vediFattureClienti',
            name: 'vediFattureClienti',
            component: vediFattureClienti
        }

        ,
        {
            path: '/vediFattureFornitori',
            name: 'vediFattureFornitori',
            component: vediFattureFornitori
        }

        ,
        {
            path: '/filterCondizioniPagamento',
            name: 'filterCondizioniPagamento',
            component: filterCondizioniPagamento
        }

        ,
        {
            path: '/condizioniPagamento',
            name: 'condizioniPagamento',
            component: condizioniPagamento
        }

        ,
        {
            path: '/editCondizioniPagamento/:id',
            name: 'editCondizioniPagamento',
            component: editCondizioniPagamento,
            props: true
        }

        ,
        {
            path: '/filterValute',
            name: 'filterValute',
            component: filterValute
        }

        ,
        {
            path: '/valute',
            name: 'valute',
            component: valute
        }

        ,
        {
            path: '/editValuta/:id',
            name: 'editValuta',
            component: editValuta,
            props: true
        }

        ,
        {
            path: '/filterIva',
            name: 'filterIva',
            component: filterIva
        }

        ,
        {
            path: '/iva',
            name: 'iva',
            component: iva
        }

        ,
        {
            path: '/editIva/:id',
            name: 'editIva',
            component: editIva,
            props: true
        }

        ,
        {
            path: '/filterUnitaDiMisura',
            name: 'filterUnitaDiMisura',
            component: filterUnitaDiMisura
        }

        ,
        {
            path: '/unitaDiMisura',
            name: 'unitaDiMisura',
            component: unitaDiMisura
        }

        ,
        {
            path: '/editUnitaDiMisura/:id',
            name: 'editUnitaDiMisura',
            component: editUnitaDiMisura,
            props: true
        }


        ,
        {
            path: '/configNotifiche',
            name: 'configNotifiche',
            component: configNotifiche
        }

        ,
        {
            path: '/company',
            name: 'company',
            component: company
        }

        ,
        {
            path: '/editCompany/:id',
            name: 'editCompany',
            component: editCompany,
            props: true
        }


        ,
        {
            path: '/vediPerformanceFatturazione',
            name: 'vediPerformanceFatturazione',
            component: vediPerformanceFatturazione
        }

        ,
        {
            path: '/filterOrdiniClienti',
            name: 'filterOrdiniClienti',
            component: filterOrdiniClienti
        }

        ,
        {
            path: '/ordiniClienti',
            name: 'ordiniClienti',
            component: ordiniClienti
        }

        ,
        {
            path: '/filterOrdiniFornitori',
            name: 'filterOrdiniFornitori',
            component: filterOrdiniFornitori
        }

        ,
        {
            path: '/ordiniFornitori',
            name: 'ordiniFornitori',
            component: ordiniFornitori
        }

        ,
        {
            path: '/ordiniClientiAgila',
            name: 'ordiniClientiAgila',
            component: ordiniClientiAgila
        }

        ,
        {
            path: '/filterOrdiniClientiAgila',
            name: 'filterOrdiniClientiAgila',
            component: filterOrdiniClientiAgila
        }

        ,
        {
            path: '/editOrdiniClientiAgila/:id',
            name: 'editOrdiniClientiAgila',
            component: editOrdiniClientiAgila,
            props: true
        }

        ,
        {
            path: '/livelliGM',
            name: 'livelliGM',
            component: livelliGM,
            props: true
        }

        ,
        {
            path: '/editOrdineCliente/:id',
            name: 'editOrdineCliente',
            component: editOrdineCliente,
            props: true
        }

        ,
        {
            path: '/checkScadenze',
            name: 'checkScadenze',
            component: checkScadenze,
            props: true
        }

        ,
        {
            path: '/filterProgettiFinanziari',
            name: 'filterProgettiFinanziari',
            component: filterProgettiFinanziari,
            props: true
        }

        ,
        {
            path: '/progettiFinanziari',
            name: 'progettiFinanziari',
            component: progettiFinanziari,
            props: true
        }


        ,
        {
            path: '/editOrdineFornitore/:id',
            name: 'editOrdineFornitore',
            component: editOrdineFornitore,
            props: true
        }


        ,
        {
            path: '/budgetPerformancePrevisto',
            name: 'budgetPerformancePrevisto',
            component: budgetPerformancePrevisto,
            props: true
        }


        ,
        {
            path: '/budgetPerformanceRisultati',
            name: 'budgetPerformanceRisultati',
            component: budgetPerformanceRisultati,
            props: true
        }

        ,
        {
            path: '/vediOrdiniClienti',
            name: 'vediOrdiniClienti',
            component: vediOrdiniClienti,
            props: true
        }

        ,
        {
            path: '/vediOrdiniFornitori',
            name: 'vediOrdiniFornitori',
            component: vediOrdiniFornitori,
            props: true
        }

        ,
        {
            path: '/editProgettoFinaziario/:id',
            name: 'editProgettoFinaziario',
            component: editProgettoFinaziario,
            props: true
        },

        {
            path: '/loginTest',
            name: 'loginTest',
            component: loginTest,
            props: true
        }


    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)

})


export default router
