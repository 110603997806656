<template>
    <div>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <!-- START -->
                                <div class="sidemenu__sub-items-container" id="menu-12">


                                    <div class="sidemenu__group-container">
                                        <div v-if="(isPwdChangedOnce > 0) && (viewCDC == 1 || viewTipoCDC == 1)"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezCDC" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">CDC</div>

                                        </div>

                                        <div style="border-top: 1px solid #ffed00;" v-if="viewSezCDC == 'S'">

                                            <ul class="group__items">


                                                <a v-if="isPwdChangedOnce > 0 && viewCDC == 1" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/cdc_prjweb.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">CDC</span>
                                                </a>

                                                <a v-if="isPwdChangedOnce > 0 && viewTipoCDC == 1"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/tipo_cdc_prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Tipo CDC</span>
                                                </a>


                                            </ul>

                                        </div>



                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="(isPwdChangedOnce > 0) && (viewLivGM == 1 || viewCheckScad == 1)"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezImpostazioni" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Impostazioni</div>


                                        </div>


                                        <div style="border-top: 1px solid #ffed00;" v-if="viewSezImpostazioni == 'S'">

                                            <ul class="group__items">


                                                <a v-if="isPwdChangedOnce > 0 && viewCheckScad == 1"
                                                    @click="goto('/checkScadenze')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/gross-margin-prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Check Scadenza</span>
                                                </a>


                                                <a v-if="isPwdChangedOnce > 0 && viewLivGM == 1"
                                                    @click="goto('/livelliGM')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/gross-margin-prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Livello GM</span>
                                                </a>



                                            </ul>

                                        </div>



                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="(isPwdChangedOnce > 0) && (viewGruppiMail == 1 || viewMail == 1)"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezMail" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Mail</div>

                                        </div>

                                        <div style="border-top: 1px solid #ffed00;" v-if="viewSezMail == 'S'">

                                            <ul class="group__items">


                                                <a v-if="isPwdChangedOnce > 0 && viewGruppiMail == 1"
                                                    @click="goto('/mailGruppo')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/gruppo-mail-prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Gruppi</span>
                                                </a>

                                                <a v-if="isPwdChangedOnce > 0 && viewMail == 1"
                                                    @click="goto('/confInvioMail')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/configurazione-invio-mail-prjweb.png"
                                                        max-width="32" max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Invio</span>
                                                </a>



                                            </ul>



                                        </div>




                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="(isPwdChangedOnce > 0) && (viewPrat == 1 || viewClienti == 1 || viewFornitori == 1)"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezOfferteOrdini" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Offerte / Ordini</div>


                                        </div>

                                        <div style="border-top: 1px solid #ffed00;" v-if="viewSezOfferteOrdini == 'S'">


                                            <ul class="group__items">

                                                <a v-if="isAdmin == 1" @click="goto('/budgetPerformanceRisultati')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/prestaz_prjweb.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Budget</span>
                                                </a>

                                                <a v-if="isPwdChangedOnce > 0 && viewClienti == 1"
                                                    @click="goto('/vediOrdiniClienti')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/icona-ordine-prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Clienti</span>
                                                </a>

                                                <a v-if="isPwdChangedOnce > 0 && viewFornitori == 1"
                                                    @click="goto('/vediOrdiniFornitori')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/icona-offerta-prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Fornitori</span>
                                                </a>

                                                <a v-if="isPwdChangedOnce > 0 && viewPrat == 1"
                                                    @click="goto('/ordiniClientiAgila')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/pratiche-prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Pratiche</span>
                                                </a>

                                            </ul>


                                        </div>





                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="(isPwdChangedOnce > 0) && (viewProgetti == 1 || viewProgettiMaster == 1 || viewOreTask == 1 || viewAttivita == 1 || viewAssocRis == 1)"
                                            class="group__text clRowSectionMenu">


                                            <div>
                                                <v-switch v-model="viewSezProgettiFinanziari" false-value="N"
                                                    true-value="S" hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Progetti Finanziari</div>


                                        </div>

                                        <div style="border-top: 1px solid #ffed00;"
                                            v-if="viewSezProgettiFinanziari == 'S'">

                                            <ul class="group__items">


                                                <a v-if="(isPwdChangedOnce > 0) && viewAssocRis == 1"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/assoc_ris_task_prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Associazione Risorse Task</span>
                                                </a>

                                                <a v-if="(isPwdChangedOnce > 0) && viewAttivita == 1"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/attivita_prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Attività</span>
                                                </a>

                                                <a v-if="(isPwdChangedOnce > 0) && viewOreTask == 1"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/ore_task_prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Ore Task</span>
                                                </a>

                                                <a v-if="(isPwdChangedOnce > 0) && viewProgetti == 1"
                                                    mid="mnu_gest_company" @click="goto('/filterProgettiFinanziari')"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/progetti_prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Progetti</span>
                                                </a>

                                                <a v-if="(isPwdChangedOnce > 0) && viewProgettiMaster == 1"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/progetti_master_prjweb.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Progetti Master</span>
                                                </a>


                                            </ul>

                                        </div>



                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="(isPwdChangedOnce > 0) && (viewProfilo == 1 || viewUtenti == 1)"
                                            class="group__text clRowSectionMenu">


                                            <div>
                                                <v-switch v-model="viewSezUtenti" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Utenti</div>


                                        </div>

                                        <div style="border-top: 1px solid #ffed00;" v-if="viewSezUtenti == 'S'">

                                            <ul class="group__items">

                                                <a v-if="isPwdChangedOnce > 0 && viewUtenti == 1"
                                                    @click="goto('/filterusers')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/users-prjweb.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Utenti</span>
                                                </a>

                                            </ul>


                                        </div>





                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div class="group__text" style="border-color: #ffed00;display:flex;justify-items: center;height: 40px;padding-top: 10px;">
                                            <div style="margin-left: 5px;">Applicazione</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="isPwdChangedOnce > 0" @click="goto('/dash')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/home-prjweb.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Home</span>
                                            </a>

                                            <a v-if="isPwdChangedOnce > 0" @click="goto('/info')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/info-prjweb.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Info</span>
                                            </a>


                                            <a @click="logout" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Logout" class="  " contain src="@/assets/exit-prjweb.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Esci</span>
                                            </a>

                                        </ul>
                                    </div>
                                </div>

                                <!-- END -->

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>
</template>

<script>

import apispese from "../utils/spese/apispese";

//import $ from 'jquery';
import _ from 'lodash';

export default {
    name: "menubottom",
    props: {},

    mounted: function () {

        this.setGlobal();

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");
        this.isAgila = window.$cookies.get("is_agila");

        //var v_models = window.$cookies.get("models");
        var user_models = window.$cookies.get("user_models");

        console.log("USER MODELS: ", user_models);
        console.log("IS AGILA: ", this.isAgila);

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }

        if (this.isSU == 0) {

            this.viewProgetti = this.getEnableConf("prj_fin");
            this.viewProgettiMaster = this.getEnableConf("prj_master");
            this.viewOreTask = this.getEnableConf("ore_task");
            this.viewAttivita = this.getEnableConf("attivita");
            this.viewAssocRis = this.getEnableConf("assoc_ris_task");
            this.viewPrat = this.getEnableConf("prat_agila");
            this.viewClienti = this.getEnableConf("ordini_clienti");
            this.viewFornitori = this.getEnableConf("ordini_fornitori");
            this.viewLivGM = this.getEnableConf("livello_gm");
            this.viewCheckScad = this.getEnableConf("check_scadenze");
            this.viewCDC = this.getEnableConf("cdc");
            this.viewTipoCDC = this.getEnableConf("tipo_cdc");
            this.viewGruppiMail = this.getEnableConf("gruppi_mail_prjweb");
            this.viewMail = this.getEnableConf("invio_mail_prjweb");
            this.viewProfilo = this.getEnableConf("profilo_prjweb");
            this.viewUtenti = this.getEnableConf("utenti_prjweb");


        }
        else {

            this.viewProgetti = 1;
            this.viewProgettiMaster = 1;
            this.viewOreTask = 1;
            this.viewAttivita = 1;
            this.viewAssocRis = 1;
            this.viewPrat = 1;
            this.viewClienti = 1;
            this.viewFornitori = 1;
            this.viewLivGM = 1;
            this.viewCheckScad = 1;
            this.viewCDC = 1;
            this.viewTipoCDC = 1;
            this.viewGruppiMail = 1;
            this.viewMail = 1;
            this.viewProfilo = 1;
            this.viewUtenti = 1;

        }



    },
    data() {

        return {

            viewSezProgettiFinanziari: "N",
            viewSezOfferteOrdini: "N",
            viewSezImpostazioni: "N",
            viewSezCDC: "N",
            viewSezMail: "N",
            viewSezUtenti: "N",

            viewProgetti: 0,
            viewProgettiMaster: 0,
            viewOreTask: 0,
            viewAttivita: 0,
            viewAssocRis: 0,
            viewPrat: 0,
            viewClienti: 0,
            viewFornitori: 0,
            viewLivGM: 0,
            viewCheckScad: 0,
            viewCDC: 0,
            viewTipoCDC: 0,
            viewGruppiMail: 0,
            viewMail: 0,
            viewProfilo: 0,
            viewUtenti: 0,

            isAgila: 0,
            isSU: 0,

            isAdmin: 0,
            isPwdChangedOnce: 0,
            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },

        checkEnableSpese: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("TOKEN: ", v_token);

            that.$root.$children[0].showProgress = true;
            var response = await apispese.validateEnableSpese(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validateEnableSpese", res);

                if (res.data.Result == "OK") {

                    setTimeout(
                        () => {

                            return this.$router.push("/spese");
                        }
                        , 300);



                }

                if (res.data.Result == "ERROR") {


                    this.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }).catch(err => {

                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);
                console.log("err", err);

            }

            );



        },

        logout: function () {
            this.sheetAziende = false;
            this.$root.$children[0].logout();
        },
        goto: function (link) {

            this.sheetAziende = false;

            setTimeout(
                () => {

                    return this.$router.push(link);
                }
                , 300);


            // if (link != "/spese") {

            //     setTimeout(
            //         () => {

            //             return this.$router.push(link);
            //         }
            //         , 300);

            // }

            // if (link == "/spese") {

            //     this.checkEnableSpese();

            // }



            // console.log("LINK: ", link);

            // if ((link == "/profile") || (link == "/messages")) {

            //     this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;
            // }




            // setTimeout(
            //     () => {

            //         return this.$router.push(link);
            //     }
            //     , 300);


        },


        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openmenu: function () {

            this.setGlobal();

            this.sheetAziende = true;
        },
        manageClose: function () {

            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;


            this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;


        },

    }
};
</script>

<style scoped>
/****** MENU ******/
.menu-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.menu__item .title,
.menu-icon .title {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .05em;
}

.menu__settings-icon {
    height: 26px !important;
    width: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 2px !important;
}

#sidemenu {

    z-index: 2;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    position: fixed;
    top: 54px;
    left: 0;
    background: transparent;
    bottom: 56px;
    padding: 28px 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

#sidemenu.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#sidemenu .sidemenu__inner-wrapper {
    overflow: hidden;
    background: white;
    height: 100%;
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
}

.sidemenu__top-level-item {
    width: 83px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    opacity: 1;
}

sidemenu__top-level-item a {
    width: 80px;
}

.sidemenu__top-level-item>a {
    height: 80px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.sidemenu__top-level-item>a:before {
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 3px;
    z-index: 1;
    content: '';
    display: block;
}

.sidemenu__top-level-item>a:hover {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    opacity: .7;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    display: block;
    margin-bottom: 4px;
    width: 30px;
}

.sidemenu__top-level-item:hover {
    background-color: #f9f9f9;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__icon {
    -webkit-filter: none;
    filter: none;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__text {
    opacity: 1;
}

/*
  .sidemenu__sub-items-container {
    z-index: -1;
    position: absolute;
    background: white;
    overflow-y: auto;
    top: 28px;
    left: 100%;

    width: 359px;
    bottom: 28px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    border: 1px solid #efefef;

    text-align: left;

  }*/
.sidemenu__sub-items-container .group__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1px;
    position: relative;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem,
.sidemenu__top-level-item>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    width: 84px
        /* 84px */
    ;
    height: 80px;
    position: relative;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
}

.sidemenu__subitem {
    width: 120px !important;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem:hover {
    background: #f9f9f9;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem img,
.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    width: 28px;
    margin-bottom: 20px;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    margin-bottom: 20px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem .subitem__text,
.sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 80px;
    font-size: 9.5px;
    line-height: 1.05em;
    font-weight: 500;
    color: #404040;
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    top: 50px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 1.1em;
    font-weight: 600;
    font-size: 9px;
}

.sidemenu__sub-items-container .group__text {
    background-color: #f9f9f9;
    font-weight: 700;
    padding: 20px 16px 6px 16px;
    padding: 1px;

    font-size: 12px;
    color: rgba(0, 0, 0, 0.78);
    letter-spacing: 0px;
    border-bottom: 2px solid #f3f3f3;
}

.sidemenu__sub-items-container.hidden {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;


    justify-content: start;

    bottom: 56px;

    max-height: calc(100vh - 120px);

}

.sidemenu__group-container ul {
    padding-left: 0px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container img {
    max-width: 26px;
}

.v-image__image.v-image__image--contain {
    transform: translateY(-6px);
}

.sidemenu__group-container .v-image.v-responsive.theme--light {
    margin-bottom: 22px;
}

@media screen and (max-width: 768px) {

    .sidemenu__sub-items-container {
        justify-content: start;
        display: flex;
        max-height: calc(100vh - 120px);
    }


    .sidemenu__sub-items-container {
        z-index: 1;

        position: absolute;
        position: fixed;
        background: white;
        overflow-y: auto;
        width: 100vw;
        bottom: 0px;
        -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
        transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
        -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
        transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
        -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
        -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
        transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
        -webkit-box-shadow: 0px 2px 5px #d2d2d2;
        box-shadow: 0px 2px 5px #d2d2d2;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-content: inherit;


        justify-content: start;

        bottom: 56px;

        max-height: calc(100vh - 120px);

    }



}
</style>

<style>
.clRowSectionMenu {
    display: flex;
    justify-items: center;
    height: 40px;
    padding-top: 7px !important;
    border-color: #f9f9f9;
}

.v-input--switch__track {
    border-radius: 3px !important;
    width: 35px !important;
    height: 7px !important;
    top: 10px !important;
    left: 6px !important;
}

.v-input--switch__thumb {
    left: 6px !important;
    height: 15px !important;
    width: 15px !important;
    top: 5px !important;
}

.custom-switch .v-input__slot .v-label {
    left: 6px !important
}

.v-input--selection-controls__ripple {
    height: 0 !important;
    width: 0 !important;
}
</style>