import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIAziende {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initInsertAzienda(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtInitAddAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async validateVATAzienda(id, vat, user) {

    let formData = new FormData();

    formData.append("val_vat", vat);
    formData.append("val_id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtValidateVATApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertAzienda(azienda, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in azienda) {
      formData.append(key, azienda[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsertAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditAzienda(id, user) {

    return axios.get(this.getUrlDomain("EmtInitEditAziendApi") + "/" + id + "?api=1&username=" + user, { 'Content-Type': 'multipart/form-data' });

  }

  async updateAzienda(azienda, id, user) {

    let formData = new FormData();

    formData.append("user", user);

    for (var key in azienda) {

      if (azienda[key] != null) {

        formData.append(key, azienda[key]);

      }
      else {

        formData.append(key, "");

      }

    }

    return axios.post(this.getUrlDomain("EmtUpdateAziendaApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteAzienda(id, user)
  {


    return axios.get(this.getUrlDomain("EmtDeleteAziendaApi") + "?id=" + id + "&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



}

export default new APIAziende({
  url: "https://services.ebadge.it/public/api/"
})
