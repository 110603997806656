import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIInvoice {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;


  }


  async getClienti(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebGetClientiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getFornitori(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebGetFornitoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAziendaFiltro(user, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);

    return axios.post(this.getUrlDomain("InvoiceWebGetAziendaFiltroApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getInfoFattura(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_invoice", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebScadenziarioInfoFatturaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insPagamento(user, id_fattura, data_pagamento, importo_pagamento) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_invoice", id_fattura);
    formData.append("data_pagamento", data_pagamento);
    formData.append("importo_pagamento", importo_pagamento);

    return axios.post(this.getUrlDomain("InvoiceWebScadenziarioInsPagApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deletePagamenti(user, id, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_invoice", id_fattura);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("InvoiceWebScadenziarioDelPagApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteFattureClienti(user, id_fatture) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fatture);

    return axios.post(this.getUrlDomain("InvoiceWebDeleteFattureClientiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteFattureFornitori(user, id_fatture) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fatture);

    return axios.post(this.getUrlDomain("InvoiceWebDeleteFattureFornitoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async validatePdfFattura(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebValidatePdfFatturaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async createPdfFattura(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebCreatePdfFatturaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async validatePdfAnticipo(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebValidatePdfAnticipoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createPdfAnticipo(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebCreatePdfAnticipoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initParamsAutomatic(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("InvoiceWebAutoInitParamsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getDays(user, month) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("month", month);

    return axios.post(this.getUrlDomain("getDaysForMonth"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async validateAutomatic(user, month, day, company, year) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_mese", month);
    formData.append("v_azienda", company);
    formData.append("v_giorno", day);
    formData.append("v_anno", year);

    return axios.post(this.getUrlDomain("InvoiceWebAutoValidateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async createAutomatic(user, month, day, company, year, tipo) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_mese", month);
    formData.append("v_azienda", company);
    formData.append("v_giorno", day);
    formData.append("v_anno", year);
    formData.append("v_tipo_budget", tipo);

    return axios.post(this.getUrlDomain("InvoiceWebAutoCreateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async exportGridPdf(user, id_azienda, type_subject, filter_customer, filter_payment, val_date_scad_da, val_date_scad_a, val_date_emiss_da, val_date_emiss_a) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("companyid", id_azienda);
    formData.append("type_subject", type_subject);
    formData.append("curCustomer", filter_customer);
    formData.append("curPayment", filter_payment);
    formData.append("dateScadDa", val_date_scad_da);
    formData.append("dateScadA", val_date_scad_a);
    formData.append("dateEmissDa", val_date_emiss_da);
    formData.append("dateEmissA", val_date_emiss_a);

    return axios.post(this.getUrlDomain("InvoiceWebExportToPdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertClienti(user, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);


    return axios.post(this.getUrlDomain("InvoiceWebClientiInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getDettagliOrdine(data, id_ordine, user) {

    let formData = new FormData();

    formData.append("val_date", data);
    formData.append("val_id_ordine", id_ordine);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("InvoiceWebClientiDettOrdineApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertDetail(fattura_dettagli, user, id_azienda, id_ordine) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);

    for (var key in fattura_dettagli) {
      formData.append(key, fattura_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebClientiInsDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDetail(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("InvoiceWebClientiGetDetailApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateDetail(fattura_dettagli, user, id_azienda, id_ordine, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);
    formData.append("id", id);

    for (var key in fattura_dettagli) {
      formData.append(key, fattura_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebClientiUpdDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteDetail(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("InvoiceWebClientiDelDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveInvoice(fattura, user, operazione) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("operazione", operazione);

    for (var key in fattura) {
      formData.append(key, fattura[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebClientiSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditClienti(user, id_invoice) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);


    return axios.post(this.getUrlDomain("InvoiceWebClientiInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async initInsertFornitori(user, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);


    return axios.post(this.getUrlDomain("InvoiceWebFornitoriInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async saveInvoiceFornitori(fattura, user, operazione, file) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("operazione", operazione);
    formData.append("file" ,  file);

    for (var key in fattura) {
      formData.append(key, fattura[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebFornitoriSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditFornitori(user, id_invoice, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);
    formData.append("companyid", id_azienda);


    return axios.post(this.getUrlDomain("InvoiceWebFornitoriInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async checkNumber(number, company, id, user) {

    let formData = new FormData();

    formData.append("val_number", number);
    formData.append("val_our_company", company);
    formData.append("id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("InvoiceWebClientiCheckNumberApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




}

export default new APIInvoice({
  url: "http://emtool.local/public/api/smartbook/"
})
