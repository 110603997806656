import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIControlli {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initDDL(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsInitDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInfoAzienda(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaSediOpGetInfoAzApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveSedeOperativa(sede_operativa, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in sede_operativa) {
      formData.append(key, sede_operativa[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editControllo(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditCtrlApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteControlli(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertControllo(controllo, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in controllo) {
      formData.append(key, controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaInsCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateControllo(controllo, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in controllo) {
      formData.append(key, controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaUpdCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteCondizioni(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaDeleteCtrlsCondApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async exportXls(user, curSrcText, curCompany, curPrjType, curCtrType, curStatus) {

    let formData = new FormData();

    formData.append("username", user);

    formData.append("curSrcText", curSrcText);
    formData.append("curCompany", curCompany);
    formData.append("curPrjType", curPrjType);
    formData.append("curCtrType", curCtrType);
    formData.append("curStatus", curStatus);


    return axios.post(this.getUrlDomain("EmtAgilaExportXlsCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getInfoPratica(user, idpratica) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idpratica", idpratica);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsGetInfoPratApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async loadCtrsStd(idpratica, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idp", idpratica);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsLoadStdValApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async chooseLoadDefControls(user, idpratica) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idp", idpratica);

    return axios.post(this.getUrlDomain("EmtAgilaGetCtrlsPratApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async changeStatus(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsUpdStatusApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getInfoFromEditPrat(user, idtipoprog, idtipoctr, idazi) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("idtipoprog", idtipoprog);
    formData.append("idtipoctr", idtipoctr);
    formData.append("idazi", idazi);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsGetInfoPrtEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APIControlli({
  url: "https://services.ebadge.it/public/api/"
})
