var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"w-container v-overflow",staticStyle:{"padding-top":"59px"}},[_c('v-row',[_c('v-col',{staticClass:"mainCont",staticStyle:{"min-width":"100vw","position":"fixed","left":"0","padding-left":"0px","z-index":"3"}},[_c('div',{staticClass:"mainTitle",staticStyle:{"background-color":"#ffed00","color":"white","font-weight":"bold","padding":"4px","font-size":"10px","min-width":"100vw"}},[_vm._v(_vm._s(_vm.titolo))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","xs":"12"}},[_vm._v(" Foto "),_c('div',{attrs:{"id":"preview"}}),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"files"},[_c('label',{staticStyle:{"margin-left":"11px","font-size":"11px"}}),_c('input',{staticClass:"dropify",attrs:{"type":"file","id":"input-file-logo","name":"logo","accept":".png,.jpg,.jpeg","data-max-width":"6610","data-max-height":"6200"}})])]),_c('img',{attrs:{"id":"imgPreview"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"id":"fldInpDescription","label":"Descrizione"},model:{value:(_vm.spese.description),callback:function ($$v) {_vm.$set(_vm.spese, "description", $$v)},expression:"spese.description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transitions":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"id":"fldInpData","label":"Data","persistent-hint":"","readonly":""},model:{value:(_vm.spese.date),callback:function ($$v) {_vm.$set(_vm.spese, "date", $$v)},expression:"spese.date"}},on))]}}]),model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"it"},on:{"input":function($event){_vm.showPicker = false}},model:{value:(_vm.spese.date),callback:function ($$v) {_vm.$set(_vm.spese, "date", $$v)},expression:"spese.date"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted cl_importo",attrs:{"id":"d_importo"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}},[_vm._v("Importo")]),_c('vue-autonumeric',{attrs:{"id":"fldInpAmount","disabled":true,"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '\u00a0€',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0'
                                }},model:{value:(_vm.spese.amount),callback:function ($$v) {_vm.$set(_vm.spese, "amount", $$v)},expression:"spese.amount"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1)],1),_c('div',{staticClass:"contpopupimporto"},[[_c('modal',{attrs:{"name":"popupImporto","clickToClose":false,"width":300,"height":180}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"15px","margin-left":"8px"}},[_vm._v("Importo")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",staticStyle:{"cursor":"pointer"},attrs:{"id":"d_importo_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldImporto",attrs:{"options":{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        },"id":"fldImporto"},model:{value:(_vm.valoreImporto),callback:function ($$v) {_vm.valoreImporto=$$v},expression:"valoreImporto"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-img',{staticClass:"btn-cancel-importo",staticStyle:{"cursor":"pointer","color":"black","margin-right":"10px"},attrs:{"alt":"","contain":"","src":require("@/assets/btn_cancel3_prjweb.png"),"max-width":"30","max-height":"30","transition":"scale-transition"}}),_c('v-img',{staticClass:"btn-confirm-importo",staticStyle:{"cursor":"pointer"},attrs:{"alt":"","contain":"","src":require("@/assets/btn_confirm3_prjweb.png"),"max-width":"30","max-height":"30","transition":"scale-transition"}})],1)])],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }